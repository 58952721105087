import React, {useEffect, useState} from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import Spinner from '../../../common/spinner';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextArea from '../../../common/textArea';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { GetToOrganisation } from '../../lib/getToOrganisation';

import { versionListRequest } from '../../usage/action';
import {  listMessageRequest, 
    registerMessageRequest, 
    deleteMessageRequest, 
    messagePublishUnpublish
} from '../action';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);

    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }
    
    const [title, setTitle] = useState([]);
    const [agricoachVersion, setAgricoachVersion] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    const [titleError, setTitleError] = useState(false);
    const [versionError, setVersionError] = useState(false);

    const [selectedMessage, setSelectedMessage] = useState([]);

    useEffect(()=>{
        setTitle([]);
        setSelectedId('');
        setAgricoachVersion([]);
        setSelectedMessage([]);
    },[
        props.message_list_loading,
        props.message_register_loading,
        props.message_delete_loading,
        props.message_publish_unpublish_loading
    ]);

    useEffect(()=>{
        props.actions.versionListRequest();
        props.actions.listMessageRequest({
            "org_id": props.organisation_id,
            "version": ""
        })
    },[]);

    const textChange = (name, text) => { 
        switch(name){
            case 'message': 
                setTitleError(false);

                let tmp_data1 = {[name]: text};
                let tmp_data2 = getLanguage() in title ? title[getLanguage()]: [];
                let tmp_language = {"locale": languageContent};
                let tmp_data3 = {...tmp_data2, ...tmp_data1, ...tmp_language};

                let tmp_language1 = Object.values(title).findIndex((tmp)=>{
                    return tmp.locale.includes(props.lang) === true
                })

                if(tmp_language1 !== -1){
                    var tmp_language2 = tmp_language1;
                }
                else{
                    var tmp_language2 = Object.values(title).length;
                }

                let tmp_data4 = {[tmp_language2]: tmp_data3};
                let tmp1_data = {...title, ...tmp_data4};

                setTitle(tmp1_data);
                break;
            case 'version':
                setVersionError(false);
                if(agricoachVersion.includes(text) === false){
                    let tmp_version = agricoachVersion;
                    tmp_version.push(text);
                    setAgricoachVersion(tmp_version);
                }
                else{
                    let tmp_version = agricoachVersion;
                    let index = agricoachVersion.findIndex((tmp)=>{return tmp === text});
                    if(index !== -1 ){
                        tmp_version.splice(index, 1);
                        setAgricoachVersion(tmp_version);
                    }
                }
                break;
            default:
                break;
        }
    }

    const textChange1 = (message) =>{
        let data = Object.values(props.message_list).filter((tmp)=>{return tmp.id == message});

        if(data.length > 0){
            props.actions.messagePublishUnpublish(
                {
                    "message_id": message, 
                    "is_published": !data[0].is_published,
                    "org_id": props.organisation_id
                }
            );
        }
    }

    const onSaveButtonClick = () => {
        setTitleError(false);
        setVersionError(false);

        if(Object.values(title).length === 0){
            setTitleError(true);
        }

        if(Object.values(title).length > 0){
            let tmp_title = [];
            Object.values(title).map(tmp=>{
                if("message" in tmp === true){
                    tmp_title.push(tmp);
                }
                else{
                    let tmp_message = {"message": ""};
                    let tmp_data = {...tmp_message, ...tmp};
                    tmp_title.push(tmp_data);
                }
            })
            
            var add_version = [];
            var delete_version = [];

            if(selectedId === ""){
                add_version = agricoachVersion;
            }
            else{
                var diff = selectedMessage.version.filter(x=>!agricoachVersion.includes(x));
                delete_version = diff;

                let add = agricoachVersion.filter(x=>!selectedMessage.version.includes(x));
                add_version = add;
            }

            let data = {
                "org_name": props.organization,
                "is_published": true,
                "message_id": selectedId !== "" ? selectedId : "",
                "messageText": JSON.stringify(tmp_title),
                "add_version": add_version,
                "delete_version": delete_version
            }

            if(add_version.length === 0 && selectedId === ""){
                setVersionError(true);
            }
            else{
                props.actions.registerMessageRequest(data);
            }
        }
        else{
            setTitleError(true);
            if(agricoachVersion.length === 0){
                setVersionError(true);
            }
        }
    }

    const getLanguage = () => {
        let data = Object.values(title).findIndex((data)=>{
            return data.locale === languageContent;
        })
        return data;
    }

    const getLanguage1 = () => {
        let data = Object.values(title).findIndex((data)=>{
            return data.locale === 'en_EN';
        })
        return data;
    }

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const convertVersionName = (version) => {
        let data = version.split(".");
        let final_data = data[0].charAt(0).toUpperCase()+data[0].slice(1) + " " + data[1].charAt(0).toUpperCase();
        return final_data;
    }

    const getMessageLanguage = (text) => {
        if(Object.values(text).length > 0){
            let data = Object.values(text).findIndex((tmp)=>{
                return tmp.locale.includes(props.lang) === true;
            });
            return data;
        }
        else{
            return -1;
        }
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal =  () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete {getMessageLanguage(selectedMessage.messagetext) !== -1 ? selectedMessage.messagetext[getMessageLanguage(selectedMessage.messagetext)].message : "selected usage"} ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                props.actions.deleteMessageRequest({"message_id": selectedMessage.id, "org_id": props.organisation_id})
                                closeModal();
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <Breadcrumb 
                save={
                    (props.user_role_type !== "Viewer" && props.organisation === to_org)  ? 
                        true 
                    : 
                        (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  ? 
                            true
                        :
                            false
                }   
                onSaveButtonClick={onSaveButtonClick}
            />
 
            <div className="generalBody" style={{flexDirection: 'column'}}>
                <ToastContainer/>
                
                {
                    (props.message_publish_unpublish_loading === true || props.message_delete_loading === true || props.message_list_loading === true || props.message_register_loading === true) ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column', flex: 1}}>
                            {
                                props.user_role_type !== "Viewer" &&
                                    <div style={{flexDirection: 'row', flex: 1}}>
                                    {
                                        props.user_role_type !== "Viewer" &&
                                            <div className="leftFormCard" >
                                                {
                                                    languageLength.map((lang,key)=>{
                                                        return (
                                                            langContent === lang &&
                                                                <div key={key}>
                                                                    {
                                                                        Object.values(selectedMessage).length > 0 ?
                                                                            <TextArea
                                                                                label="Message" 
                                                                                name="message"
                                                                                id="message"
                                                                                onTextChange={textChange}
                                                                                value={
                                                                                    getLanguage() in title === true ? 
                                                                                        title[getLanguage()].message
                                                                                    :
                                                                                        undefined
                                                                                }
                                                                                value1={
                                                                                    languageContent !== 'en_EN' ?
                                                                                        getLanguage1() in title ? 
                                                                                            title[getLanguage1()].message
                                                                                        :
                                                                                            undefined
                                                                                    :
                                                                                        undefined
                                                                                }
                                                                            />
                                                                        :
                                                                            <TextArea
                                                                                label="Message" 
                                                                                name="message"
                                                                                id="message"
                                                                                onTextChange={textChange}
                                                                                value={getLanguage() in title ? title[getLanguage()].message : undefined}
                                                                                value1={
                                                                                    languageContent !== "en_EN" ?
                                                                                        getLanguage1() in title ? title[getLanguage1()].message : undefined
                                                                                    :
                                                                                        undefined
                                                                                }
                                                                                error={titleError === true ? "Message is required" : ""}
                                                                            />
                                                                    }
                                                                </div>
                                                        )
                                                    })
                                                }

                                                <div className="TextBoxContainer" >
                                                    <div className="leftSelectLabelContainer1">
                                                        <span>Versions</span>
                                                    </div>

                                                    <div className="rightSelectLabelContainer1">
                                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                                            {
                                                                versionError === true &&
                                                                    <span className="errorText">{"One of the version needs to be selected."}</span>
                                                            }
                                                            <div style={{display: "flex",flexDirection: 'row', flexWrap: 'wrap'}}>
                                                                {
                                                                    Object.values(props.version_list).length &&
                                                                        Object.values(props.version_list).map((version, key)=>{
                                                                            return(
                                                                                agricoachVersion.includes(version.version) ===  true ?
                                                                                    <label className="container" key={key}>
                                                                                        {convertVersionName(version.version)}
                                                                                        <input 
                                                                                            id={"version"+key}
                                                                                            onChange={(e)=>textChange('version',e.target.name)} 
                                                                                            type="checkbox" 
                                                                                            name={version.version} 
                                                                                            value={version.version} 
                                                                                            defaultChecked={true}
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                :
                                                                                    <label className="container" key={key}>
                                                                                        <input 
                                                                                            id={"version"+key}
                                                                                            type="checkbox" 
                                                                                            name={version.version} 
                                                                                            value={version.version} 
                                                                                            onChange={(e)=>textChange('version',e.target.name)} 
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                        {convertVersionName(version.version)}
                                                                                    </label>
                                                                            )
                                                                        })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    </div>
                            }

                            <table style={{width: "100%"}} className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Message</th>
                                        <th>Version</th>
                                        {
                                            props.user_role_type !== "Viewer" &&
                                                <>
                                                    <th>
                                                        <span>Edit</span> 
                                                    </th>
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                                </>
                                        }
                                    </tr>
                                </thead> 
                                {
                                    props.message_list !== undefined &&
                                        props.message_list.length > 0 &&
                                            props.message_list.map((message, key)=>{  
                                                return (
                                                    <tbody key={key}>
                                                        <tr>
                                                            <td style={{width: 1}}>
                                                                <label className="container">
                                                                    <input 
                                                                        onChange={(e)=>textChange1(e.target.value)} 
                                                                        type="checkbox" 
                                                                        value={message.id} 
                                                                        defaultChecked={message.is_published === true ? true : false}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </td>
                                                            <td>{getMessageLanguage(message.messagetext) !== -1 ? message.messagetext[getMessageLanguage(message.messagetext)].message : ""}</td>
                                                            <td style={{display: 'flex', flexDirection: "column"}}>
                                                                {
                                                                    ["null",undefined,"",null].includes(message.version) === false &&
                                                                        message.version.length > 0 &&
                                                                            message.version.map((tmp,key)=>{
                                                                                return (
                                                                                    <span key={key}>{convertVersionName(tmp)}{ key !== message.version.length - 1 ? ", " : ""}</span>
                                                                                )
                                                                            })
                                                                }
                                                            </td>
                                                            {
                                                                props.user_role_type !== "Viewer" &&
                                                                    <>
                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/edit.svg')} 
                                                                                alt="edit" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={()=>{
                                                                                    document.getElementById('message').value = "";
                                                                                    document.getElementById('message').value = message.messagetext[getMessageLanguage(message.messagetext)].message;
                                                                                    
                                                                                    let temp_message_version =  [null, "", undefined, "null"].includes(message.version) === false ? [...message.version] : [];
                                                                                
                                                                                    setTitle(message.messagetext);

                                                                                    setSelectedId(message.id);

                                                                                    setSelectedMessage(message);
                                                                                    
                                                                                    setAgricoachVersion([...temp_message_version]);
                                                                                    window.scrollTo(0,0);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/delete.png')} 
                                                                                alt="delete" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={(e)=>{
                                                                                    e.stopPropagation()
                                                                                    e.preventDefault()
                                                                                    openModal();
                                                                                    setSelectedMessage(message);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </>
                                                            }
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                }
                            </table>
                        </div>
                }
            </div>

            {
                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                    Object.values(selectedMessage).length > 0 &&
                        renderModal()
                :
                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                        Object.values(selectedMessage).length > 0 &&
                            renderModal()
                    
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        message_list_loading: state.message.message_list_loading,
        message_register_loading: state.message.message_register_loading,
        message_delete_loading: state.message.message_delete_loading,
        message_publish_unpublish_loading: state.message.message_publish_unpublish_loading,
        organisation_id: state.auth.uid,
        lang: state.lang.language, 
        version_list: state.usage.version_list,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        message_list: state.message.message_list,
        organization: state.auth.organization,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                versionListRequest,
                listMessageRequest,
                registerMessageRequest,
                deleteMessageRequest,
                messagePublishUnpublish
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);