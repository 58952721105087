import React, { useState, useEffect } from 'react';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../../../trans/actions';
import { 
    activityList,
    deleteActivityCategory
 } from '../action';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'; 
import Switch from '../../../common/switch';
import Spinner from '../../../common/spinner';
import { GetToOrganisation } from '../../lib/getToOrganisation';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const ActiivtyList = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [selectedActivity, setSelectedActivity] = useState();
    const [activityList, setActivityList] = useState([]);

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{ 
        setSelectedActivity(selectedActivity);
    },[selectedActivity]);

    useEffect(()=>{
        props.actions.activityList();
    },[]);

    useEffect(()=>{
        setActivityList(props.activity_list);
    },[props.activity_list]);

    const editActivity = (activity) => {
        document.getElementById('name').value = "activity_text" in activity && languageContent in activity.activity_text ? activity.activity_text[languageContent].name : "";
        document.getElementById('description').value = "activity_text" in activity && languageContent in activity.activity_text ? activity.activity_text[languageContent].description : "";
    }

    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const getSelectedLanguageData = (offset) => {
        let index = offset.findIndex((tmp)=>{return tmp.locale.includes(props.lang)});

        if(index === -1){
            return -1;
        }
        else{
            return index;
        }
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete Category?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                props.actions.deleteActivityCategory(selectedActivity)
                                closeModal();
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <div>
            <ToastContainer/>
            {
                props.loading &&
                    <Spinner />
            }
            {
                Object.keys(props.activity_list).length > 0 &&
                    <table className="tableContainer" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                {
                                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                        <th colSpan={3} width="40%">Name</th>
                                    :
                                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) ?
                                            <th colSpan={3} width="40%">Name</th>
                                        :
                                            <th colSpan={2} width="40%">Name</th>
                                }
                                <th>ID</th>
                                <th>Generic</th>
                                <th>Category</th>
                                <th>Weather Dependent</th>
                                <th>Sorting</th>
                                {
                                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                        <>
                                            <th><span>Edit</span></th>
                                            <th><span>Delete</span></th>
                                        </>
                                    :
                                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                            <>
                                                <th><span>Edit</span></th>
                                                <th><span>Delete</span></th>
                                            </>
                                }
                            </tr>
                        </thead> 
                        {
                            props.activity_loading ?
                                <Spinner />
                            :
                                Object.values(activityList).map((activity, key)=>{
                                    return (
                                        <tbody key={key} className={selectedActivity !== "" ? activity.id === selectedActivity ? "selectedRow" : "" : ""}>
                                            <tr className={activity.id === selectedActivity ?  "selectedRow" : "" }>
                                                {
                                                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                                        <td>
                                                            <Switch
                                                                id="weather_dependent"
                                                                name="isWeatherDependent"
                                                                onTextChange={()=>{
                                                                    props.toggleStatus(activity)
                                                                }}
                                                                selected={true}
                                                                checked={activity.status}   
                                                            />
                                                        </td>
                                                    :
                                                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                                            <td>
                                                                <Switch
                                                                    id="weather_dependent"
                                                                    name="isWeatherDependent"
                                                                    onTextChange={()=>{
                                                                        props.toggleStatus(activity)
                                                                    }}
                                                                    selected={true}
                                                                    checked={activity.status}   
                                                                />
                                                            </td>
                                                }
                                                {
                                                    activity.id === activity.category ?
                                                        activity &&
                                                            <td >{
                                                                "activity_text" in activity &&
                                                                    <>
                                                                        <span>
                                                                            {
                                                                                activity.image &&
                                                                                    <img 
                                                                                    src={`data:image/png;base64, ${atob(activity.image)}`} 
                                                                                    style={{objectFit: 'cover',paddingRight: '7px',marginBottom: '-10px'}}
                                                                                    width={"50"} height={"50"}
                                                                                    />
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                Object.values(activity.activity_text).length > 0 &&
                                                                                    getSelectedLanguageData(activity.activity_text) !== -1 ?
                                                                                        Object.values(activity.activity_text)[getSelectedLanguageData(activity.activity_text)].name
                                                                                    : 
                                                                                        ""
                                                                            }
                                                                        </span>
                                                                    </>
                                                            }</td>
                                                    :   
                                                        <td></td>
                                                } 
                                                {
                                                    activity.id !== activity.category  ?
                                                        activity.activity_text !== undefined &&
                                                            <td >
                                                                {
                                                                    <>
                                                                        <span>
                                                                            {
                                                                                activity.image &&
                                                                                    <img 
                                                                                        src={`data:image/png;base64, ${atob(activity.image)}`} 
                                                                                        style={{objectFit: 'cover',paddingRight: '7px',marginBottom: '-10px'}}
                                                                                        width={"50"} height={"50"}
                                                                                    />
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                getSelectedLanguageData(activity.activity_text) !== -1 ?
                                                                                    Object.values(activity.activity_text)[getSelectedLanguageData(activity.activity_text)].name
                                                                                :
                                                                                    ""
                                                                            }
                                                                        </span>
                                                                    </>
                                                                }
                                                            </td>
                                                    :
                                                        <td></td>
                                                }
                                                <td>{activity.id}</td>
                                                <td>{activity.generic === true ? "True" : "False"}</td>
                                                <td>{activity.category}</td>
                                                <td>{activity.weatherDependent === true ? "True" : "False"}</td>
                                                <td>{activity.sorting}</td>
                                                {
                                                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                                        <>
                                                            <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                <img 
                                                                    src={require('../../../images/edit.svg')} 
                                                                    alt="edit" 
                                                                    style={{width: 20,height: 20,cursor: "pointer"}}
                                                                    onClick={()=>{
                                                                        document.getElementById('name').value = ""
                                                                        document.getElementById('description').value = ""
                                                                        setSelectedActivity(activity.id)
                                                                        props.editVariety(activity);
                                                                        editActivity(activity);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                <img 
                                                                    src={require('../../../images/delete.png')} 
                                                                    alt="edit" 
                                                                    style={{width: 20,height: 20,cursor: "pointer"}}
                                                                    onClick={()=>{
                                                                        setSelectedActivity(activity.id)
                                                                        props.onDelete();
                                                                        openModal();
                                                                    }}
                                                                />
                                                            </td>
                                                        </>
                                                    :
                                                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                                            <>
                                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                    <img 
                                                                        src={require('../../../images/edit.svg')} 
                                                                        alt="edit" 
                                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                                        onClick={()=>{
                                                                            document.getElementById('name').value = ""
                                                                            document.getElementById('description').value = ""
                                                                            setSelectedActivity(activity.id)
                                                                            props.editVariety(activity);
                                                                            editActivity(activity);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                    <img 
                                                                        src={require('../../../images/delete.png')} 
                                                                        alt="edit" 
                                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                                        onClick={()=>{
                                                                            setSelectedActivity(activity.id)
                                                                            props.onDelete();
                                                                            openModal();
                                                                        }}
                                                                    />
                                                                </td>
                                                            </>
                                                }
                                            </tr>
                                        </tbody>
                                    )
                                })
                        }
                    </table>   
            }
            {
                (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                    renderModal()
                :
                    (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                        renderModal()
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language, 
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        loading: state.activity.loading,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
        activity_loading: state.activity.activity_loading,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                activityList,
                deleteActivityCategory,
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ActiivtyList);