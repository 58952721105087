import React, { useEffect, useState } from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox'; 
import TextArea from '../../../common/textArea';
import CustomButton from '../../../common/button';
import '../../../components/index.scss';
import { 
    pestDiseasesListRequest,  
    setPestDiseases, 
    selectPestDiseases,
    pestDiseasesMediaListRequest,
    selectedPestDiseasesMedia,
    registerPestDiseasesMedia,
    deletePestDiseasesRequest,
    deleteMedia,
    registerPestDiseasesRequest
} from '../../crop/action';
import Spinner from '../../../common/spinner';
import { 
    languageUpdate
} from '../../../trans/actions';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { GetToOrganisation } from '../../lib/getToOrganisation';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [modalIsOpen1,setIsOpen1] = React.useState(false);
    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [pestDiseasesText, setPestDiseasesText] = useState("pest_disease_text" in props.selected_pest_diseases ? props.selected_pest_diseases.pest_disease_text : []);
    const [pestDiseasesData, setPestDiseasesData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [selectedCropMedia, setSelectedCropMedia] = useState(props.selected_pest_diseases_media);
    const [selectedPestDiseases, setSelectedPestDiseases] = useState(props.selected_pest_diseases);
    const [sorting, setSorting] = useState("");
    const [checkedData, setCheckedData] = useState([]);
    const [checkedPestData, setCheckedPestData] = useState([]);
    const [mediaSorting, setMediaSorting] = useState([]);
    const [mediaSortingDropDown, setMediaSortingDropDown] = useState();
    const [selectedMedia, setSelectedMedia] = useState('');

    useEffect(()=>{
        let temp_locale = [];
        props.language_list.map((tmp, key)=>{
            temp_locale.push(tmp.locale);

            if(key === Object.values(props.language_list).length - 1){
                setLanguages(temp_locale);
            }
        })
    },[]);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setMediaSortingDropDown(mediaSortingDropDown);
    },[mediaSortingDropDown]);
 
    useEffect(()=>{
        setCheckedData(checkedData);
    },[checkedData]);

    useEffect(()=>{
        setCheckedPestData(checkedPestData);
    },[checkedPestData]);

    useEffect(()=>{
        setSorting(sorting);
    },[sorting])

    useEffect(()=>{
        setSelectedCropMedia(props.selected_pest_diseases_media)
    },[props.selected_pest_diseases_media]);

    useEffect(()=>{
        setMediaData(mediaData);
    },[mediaData]);

    useEffect(()=>{
        if(props.selected_crop_id !== ""){
            props.actions.pestDiseasesListRequest(props.selected_crop_id, props.organisation);
        }
    },[props.actions,props.selected_crop_id]);

    useEffect(()=>{
        setPestDiseasesData(pestDiseasesData);
    },[pestDiseasesData]);
    
    const textChange = (name, text) => {
        var pestDiseases = ["displayName","description", "prevention","monitoring","green_measures","yellow_measures"];

        if(pestDiseases.includes(name)){
            let data1 = {[name]: text};
            let data2 = getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()]: [];
            
            let language = {"locale": languageContent};

            let language2 = getLanguage() === -1 ? Object.keys(pestDiseasesText).length : getLanguage();

            let data3 = {...data2, ...data1, ...language};
            let data4 = {[language2]: data3};
            let data = {...pestDiseasesText, ...data4};
            setPestDiseasesText(data);
        }
        else{
           let data1 = {[name]: text};
           let data = {...pestDiseasesData, ...data1};

           setPestDiseasesData(data);
        }
    }

    const textChange1 = (name, text) => {
        if(name === 'sorting'){
            setSorting(text);
        }
        else if(name === 'code'){
            setCode(text);
            setCodeError(false);
        }
        else{
            let data1 = {[name]: text};
            let data2 = getMediaLanguage() in mediaData ? mediaData[getMediaLanguage()]: [];
            
            let language = {"locale": languageContent};
            let media = "id" in selectedCropMedia ?  selectedCropMedia.media_text[0].media_id : "";
            let media_id = {"media_id": media};
            let data3 = {...media_id, ...data2, ...data1, ...language};

            let language2 = getMediaLanguage() === -1 ? Object.keys(mediaData).length : getMediaLanguage();

            let data4 = {[language2]: data3};
            let data = {...mediaData, ...data4};

            setMediaData(data);
        }
    }

    const onSaveButtonClick = () => {
        let data1 = pestDiseasesData;
        let data2 = pestDiseasesText;

        let data3 = {"pestDiseaseTextjson": data2};
    
        let data4 = {"crop_id": props.selected_crop_id}
        let data5 = {...data4, ...data1, ...data3};
        let organisation = {"organisation": props.organisation};
        let data6 = {...data5, ...{"pest_id": "id" in data5 ? data5.id : ""}, ...organisation}
        
        props.actions.registerPestDiseasesRequest(data6);
    }

    const onAddMoreButtonClick = () => {
        let crop_id = {"crop_id":props.selected_crop_id};
        let sorting1 = sorting === "" ? {"sorting": getLastIndex()} : {"sorting": sorting};
        let pest_id = {"pest_id":!props.selected_pest_diseases.id ? "" : props.selected_pest_diseases.id};
        let media_id = {"media_id": !props.selected_pest_diseases_media.id ? "" : props.selected_pest_diseases_media.id}

        if(code === ""){
            setCodeError(true);
        }
        else{
            setCodeError(false);
            if(Object.keys(mediaData).length > 0){
                let added_language = languages;

                let media_type = [];
                
                for(let i = 0; i<added_language.length ; i++){
                    let tempData = [];
                    tempData.title = i in mediaData ? mediaData[i].title : "";
                    tempData.description = i in mediaData ? mediaData[i].description === undefined ? "" : mediaData[i].description  : "";
                    tempData.mediaType = i in mediaData ? mediaData[i].mediaType === undefined ? "image/jpeg" : mediaData[i].mediaType : "image/jpeg";
                    tempData.locale = i in mediaData ? mediaData[i].locale : added_language[0]
                    media_type.push(tempData);
                }
                let organisation = {"organisation": props.organisation};
                let code_data = {"code": code};
                let data = {...crop_id, ...media_id, ...sorting1, ...pest_id, ...{"mediaText": media_type}, ...{"selected_pest_diseases": selectedPestDiseases}, ...organisation, ...code_data};
            
                props.actions.registerPestDiseasesMedia(data);
            }
        }
    }

    const getLanguage = () => {
        let index = Object.values(pestDiseasesText).findIndex((temp_data)=>{return languageContent === temp_data.locale})
        return index;
    }
    
    const getlanguageFOrPestTable = (data) => {
        let index = Object.values(data).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return index;
    }

    const getLanguage1 = () => {
        let index = Object.values(pestDiseasesText).findIndex((temp_data)=>{return 'en_EN' === temp_data.locale})
        return index;
    }

    const getMediaLanguage = () => {
        let index = Object.values(mediaData).findIndex((temp_data)=>{return languageContent === temp_data.locale});
        return index;
    }

    const getMediaLanguage1 = () => {
        let index = Object.values(mediaData).findIndex((temp_data)=>{return temp_data.locale === 'en_EN'});
        return index;
    }

    useEffect(()=>{
        if("id" in selectedPestDiseases){
            let crop_text_data = [];

            let data = {};
            for (const [key, value] of Object.entries(selectedPestDiseases)) {
                if(key !== 'pest_disease_text'){
                    data[key] = value;
                }
            }
            setPestDiseasesData(data);

            selectedPestDiseases.pest_disease_text.map((crop_data_text)=>{
                let data2 = {};
                for (const [key, value] of Object.entries(crop_data_text)) {
                    data2[key] = value;
                }
                crop_text_data.push(data2);
                return ()=>{}
            });
            setPestDiseasesText(crop_text_data);
        }
    },[selectedPestDiseases]);

    useEffect(()=>{
        if("id" in selectedCropMedia){
            let crop_text_data = [];

            let data = {};
            for (const [key, value] of Object.entries(selectedCropMedia)) {
                if(key === 'media_text'){
                    data[key] = value;
                }
                else if(key === 'sorting'){
                    setSorting(value);
                }
            }
            
            let language = {"locale": languageContent};
            let sorting = {"sorting": 1}
            let data1 = {...sorting, ...data, ...language}
            setPestDiseasesText(data1);

            selectedCropMedia.media_text.map((crop_data_text)=>{
                let data2 = {};
                for (const [key, value] of Object.entries(crop_data_text)) {
                    data2[key] = value;
                }
                crop_text_data.push(data2);
            });
            setMediaData(crop_text_data);
        }
    },[selectedCropMedia]);

    useEffect(()=>{
        if(props.pest_diseases_media_list.length > 0){
            let data0 = [];
            props.pest_diseases_media_list.map((pest_diseases)=>{
                var defaultLanguageExists = Object.values(pest_diseases.media_text).filter((tempData)=>{return tempData.locale.includes('en_EN') == true});
                let data = [];

                pest_diseases.media_text.map((temp_data)=>{
                    let data1 = [];
                    if(Object.keys(defaultLanguageExists).length > 0){
                        if(defaultLanguageExists[0].locale === temp_data.locale){
                            if(Object.keys(selectedCropMedia).length > 0){
                                if(selectedCropMedia.id != temp_data.media_id){
                                    data1.id = pest_diseases.sorting;
                                    data1.value = temp_data.title;
                                    data = data1;
                                    data0.push(data);
                                }
                            }
                            else{
                                data1.id = pest_diseases.sorting;
                                data1.value = temp_data.title;
                                data = data1;
                                data0.push(data);
                            }
                        }
                    }
                    else{
                        if(Object.keys(selectedCropMedia).length > 0){
                            if(selectedCropMedia.id != temp_data.id){
                                data1.id = pest_diseases.sorting;
                                data1.value = temp_data.title;
                                data = data1;
                                data0.push(data);
                            }
                        }
                        else{
                            data1.id = pest_diseases.sorting;
                            data1.value = temp_data.title;
                            data = data1;
                            data0.push(data);
                        }
                    }
                });
            });
            setMediaSorting(data0);
        }
    },[props.pest_diseases_media_list, selectedCropMedia]);

    useEffect(()=>{
        setSelectedPestDiseases(props.selected_pest_diseases)
    },[props.selected_pest_diseases]);

    useEffect(()=>{
        setMediaData([]);
        setSelectedCropMedia([]);
        setMediaSorting([]);
        setCode('');
    },[]);

    useEffect(()=>{
        setMediaData([]);
        setSelectedCropMedia([]);
        setMediaSorting([]);
        setCode('');
    },[
        props.pest_diseases_loading,
        props.pest_diseases_media_register_loading,
        props.delete_pest_diseases_loading,
        props.crop_delete
    ])

    const getLastIndex  = () =>{
        if(props.pest_diseases_media_list.length > 0 ){
            let sort = props.pest_diseases_media_list.sort((a,b) => (a.sorting > b.sorting) ? 1 : -1 );
            if(sort.length > 0){
                return sort[sort.length - 1].sorting + 1;
            }
            else{
                return 0;
            }
        }
        else{
           return 0; 
        }
    }

    const getLanguageIndex = (data) => {
        let data1 = Object.values(data).findIndex((tempData)=>{
            return tempData.locale === languageContent;
        })
        return data1;
    }

    const editPestDiseases = (pest_diseases) => {
        document.getElementById('pest_name').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].displayName : "";
        document.getElementById('description').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].description: "";
        document.getElementById('prevention').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].prevention : "";
        document.getElementById('monitoring').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].monitoring : "";
        document.getElementById('green_measures').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].green_measures : "";
        document.getElementById('yellow_measures').value = getLanguageIndex(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ? pest_diseases.pest_disease_text[getLanguageIndex(pest_diseases.pest_disease_text)].yellow_measures : "";
        document.getElementById('scientific_name').value = pest_diseases.scientificName;
    }

    const editPestDiseasesMedia = (media) => {
        document.getElementById('media_title').value = langContent in media.media_text ? media.media_text[langContent].title : "";
        document.getElementById('media_description').value = langContent in media.media_text ? media.media_text[langContent].description : "";
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const openModal1 = () => {
        setIsOpen1(true);
    }
    
    const closeModal1 = () => {
        setIsOpen1(false);
    }

    const getLastMediaSorting = (mediaSortingData) => {
        if(mediaSortingData.length > 0){
            return mediaSortingData[mediaSortingData.length - 1].id;
        }
        else{
            return 0;
        }
    }

    useEffect(()=>{
        document.getElementById('pest_name').value = "";
        document.getElementById('description').value = "";
        document.getElementById('prevention').value = "";
        document.getElementById('monitoring').value = "";
        document.getElementById('green_measures').value = "";
        document.getElementById('yellow_measures').value = "";
        document.getElementById('scientific_name').value = "";
        setSelectedPestDiseases([]);
    },[]);

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen1 }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal1()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected pest and diseases media ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal1();
                                props.actions.deleteMedia(props.selected_pest_diseases_media.id, "pest_diseases", props.organisation)
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal1()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    const deleteModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected pest and diseases ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal();
                                props.actions.deletePestDiseasesRequest(props.selected_pest_diseases.id, props.organisation)
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    
    return (
        <div className="mediaContainer">
            <Header header={false} />

            <Breadcrumb  />

            <div className="generalBody">
                {
                    (props.pest_diseases_loading === true || props.pest_diseases_media_register_loading === true || props.delete_pest_diseases_loading === true || props.crop_delete === true) ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column'}}>
                            <ToastContainer />
                            <>
                                {
                                    languageLength.map((lang,key)=>{
                                        return(
                                            langContent === lang &&
                                                <div key={key}>
                                                {
                                                    "id" in selectedPestDiseases ?
                                                        <TextBox 
                                                            label="Pest Name" 
                                                            id="pest_name"
                                                            onTextChange={textChange}
                                                            name="displayName"
                                                            value={
                                                                getLanguage() in pestDiseasesText ? 
                                                                    pestDiseasesText[getLanguage()].displayName
                                                                : undefined
                                                            }
                                                            value1={
                                                                languageContent !== 'en_EN' ?
                                                                    getLanguage1()  in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage1()].displayName
                                                                    : undefined
                                                                :
                                                                    undefined
                                                            }
                                                        />
                                                    :
                                                        <TextBox 
                                                            label="Pest Name" 
                                                            id="pest_name"
                                                            onTextChange={textChange}
                                                            name="displayName"
                                                            value={
                                                                getLanguage() in pestDiseasesText ? 
                                                                    pestDiseasesText[getLanguage()].displayName 
                                                                    : 
                                                                        undefined
                                                            }
                                                            value1={
                                                                languageContent !== 'en_EN' ?
                                                                    getLanguage1() in pestDiseasesText ? 
                                                                        pestDiseasesText[getLanguage1()].displayName 
                                                                    : 
                                                                        undefined
                                                                :
                                                                    undefined
                                                            }
                                                        />
                                                }
                                                </div>
                                        )
                                        })
                                }

                                {
                                    "id" in selectedPestDiseases ?
                                        <TextBox 
                                            id="scientific_name"
                                            label="Scientific Name" 
                                            onTextChange={textChange}
                                            name="scientificName"
                                            value={
                                                "id" in pestDiseasesData ? pestDiseasesData.scientificName : undefined
                                            }
                                            disabled={true}
                                        />
                                    :       
                                        <TextBox 
                                            id="scientific_name"
                                            label="Scientific Name" 
                                            name="scientificName"
                                            onTextChange={textChange}
                                            value={langContent in mediaData ? mediaData.scientific_name : undefined}
                                        />
                                }

                                {
                                    languageLength.map((lang,key)=>{
                                        return(
                                            langContent === lang &&
                                                <div key={key}>
                                                {
                                                    "id" in selectedPestDiseases ?
                                                        <div>
                                                            <TextArea
                                                                label="Description" 
                                                                id="description"
                                                                onTextChange={textChange}
                                                                name="description"
                                                                value={
                                                                    getLanguage() in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage()].description
                                                                    : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].description
                                                                        : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                label="Prevention" 
                                                                id="prevention"
                                                                onTextChange={textChange}
                                                                name="prevention"
                                                                value={
                                                                    getLanguage() in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage()].prevention
                                                                    : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].prevention
                                                                        :  undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />

                                                            <TextArea
                                                                label="Monitoring" 
                                                                id="monitoring"
                                                                onTextChange={textChange}
                                                                name="monitoring"
                                                                value={
                                                                    getLanguage() in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage()].monitoring
                                                                    : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].monitoring
                                                                        : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />

                                                            <TextArea
                                                                label="Green measures" 
                                                                id="green_measures"
                                                                onTextChange={textChange}
                                                                name="green_measures"
                                                                value={
                                                                    getLanguage() in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage()].green_measures
                                                                    : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].green_measures
                                                                        : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />

                                                            <TextArea
                                                                label="Yellow measures" 
                                                                id="yellow_measures"
                                                                onTextChange={textChange}
                                                                name="yellow_measures"
                                                                value={
                                                                    getLanguage() in pestDiseasesText ?
                                                                        pestDiseasesText[getLanguage()].yellow_measures
                                                                    : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].yellow_measures
                                                                        : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                        </div>
                                                    :
                                                        <div>
                                                            <TextArea
                                                                label="Description" 
                                                                name="description"
                                                                id="description"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()].description : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ?
                                                                            pestDiseasesText[getLanguage1()].description 
                                                                        : 
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                label="Prevention" 
                                                                id="prevention"
                                                                name="prevention"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()].prevention : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ? 
                                                                            pestDiseasesText[getLanguage1()].prevention 
                                                                        : 
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                label="Monitoring" 
                                                                id="monitoring"
                                                                name="monitoring"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()].monitoring : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ? 
                                                                            pestDiseasesText[getLanguage1()].monitoring 
                                                                        : 
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                label="Green measures" 
                                                                id="green_measures"
                                                                name="green_measures"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()].green_measures : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ? 
                                                                            pestDiseasesText[getLanguage1()].green_measures 
                                                                        : 
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                label="Yellow measures" 
                                                                id="yellow_measures"
                                                                name="yellow_measures"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in pestDiseasesText ? pestDiseasesText[getLanguage()].yellow_measures : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in pestDiseasesText ? 
                                                                            pestDiseasesText[getLanguage1()].yellow_measures 
                                                                        : 
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                        </div>
                                                }
                                                </div>
                                        )
                                        })
                                }
                            </>

                            {
                                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                    <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                        <div className="leftSelectLabelContainer1"></div>
                                        
                                        <div className="rightSelectLabelContainer1">
                                            <CustomButton
                                                name={`Save Pest and Diseases Item`} 
                                                onSaveButtonClick={onSaveButtonClick}
                                            />
                                        </div>
                                    </div>
                                :
                                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                        <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                            <div className="leftSelectLabelContainer1"></div>
                                            
                                            <div className="rightSelectLabelContainer1">
                                                <CustomButton
                                                    name={`Save Pest and Diseases Item`} 
                                                    onSaveButtonClick={onSaveButtonClick}
                                                />
                                            </div>
                                        </div>
                            }

                            <table style={{width: "100%"}} className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Display Name</th>
                                        <th>Scientific Name</th>
                                        <th>
                                            <span>Edit</span> 
                                        </th>
                                        {
                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                <th>
                                                    <span>Delete</span> 
                                                </th>
                                            :
                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                        }
                                    </tr>
                                </thead>
                                {
                                    props.pest_diseases_list.length > 0 &&
                                        props.pest_diseases_list.map((pest_diseases,key)=>{
                                            return (
                                                <tbody key={key} className={"id" in pest_diseases ? pest_diseases.id === selectedPestDiseases.id ? "selectedRow" : "" : ""}>
                                                    <tr>
                                                        <td>
                                                            {
                                                                pest_diseases.pest_disease_text.length > 0 &&
                                                                    getlanguageFOrPestTable(pest_diseases.pest_disease_text) in pest_diseases.pest_disease_text ?
                                                                        pest_diseases.pest_disease_text[getlanguageFOrPestTable(pest_diseases.pest_disease_text)].displayName
                                                                    :
                                                                        undefined
                                                            }
                                                        </td>
                                                        <td>
                                                            {pest_diseases.scientificName} 
                                                        </td>
                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                            <img 
                                                                src={require('../../../images/edit.svg')} 
                                                                alt="edit" 
                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                onClick={()=>{
                                                                    document.getElementById('pest_name').value = "";
                                                                    document.getElementById('scientific_name').value = "";
                                                                    document.getElementById('description').value = "";
                                                                    document.getElementById('prevention').value = "";
                                                                    document.getElementById('monitoring').value = "";
                                                                    document.getElementById('green_measures').value = "";
                                                                    document.getElementById('yellow_measures').value = "";
                                                                    editPestDiseases(pest_diseases);
                                                                    props.actions.selectPestDiseases(pest_diseases)
                                                                    setMediaData([]);
                                                                    setSelectedCropMedia([]);
                                                                    setSorting('');
                                                                    window.scrollTo(0, 0);  
                                                                }}
                                                            />
                                                        </td>
                                                        {
                                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                    <img 
                                                                        src={require('../../../images/delete.png')} 
                                                                        alt="delete" 
                                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                                        onClick={()=>{
                                                                            openModal();
                                                                            props.actions.selectPestDiseases(pest_diseases);
                                                                        }}
                                                                    />
                                                                </td>
                                                            :
                                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                        <img 
                                                                            src={require('../../../images/delete.png')} 
                                                                            alt="delete" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                openModal();
                                                                                props.actions.selectPestDiseases(pest_diseases);
                                                                            }}
                                                                        />
                                                                    </td>
                                                        }
                                                    </tr>
                                                    {
                                                        pest_diseases.pest_disease_text.length > 0 &&
                                                            props.langauge in pest_diseases.pest_disease_text &&
                                                                pest_diseases.pest_disease_text[langContent].description !== null &&
                                                                    <tr> 
                                                                        <td colSpan="5" style={{textAlign: 'left'}}>
                                                                            {/* {pest_diseases.pest_disease_text[props.language].description.toString().substring(0,100)}... */}
                                                                        </td>
                                                                    </tr>
                                                    }   
                                                    {
                                                        pest_diseases.pest_disease_text.length > 0 &&
                                                            props.langauge in pest_diseases.pest_disease_text &&
                                                                pest_diseases.pest_disease_text[langContent].treatment !== null &&
                                                                    <tr> 
                                                                        <td colSpan="5" style={{textAlign: 'left'}}>{pest_diseases.pest_disease_text[langContent].treatment.toString().substring(0,100)}...</td>
                                                                    </tr>
                                                    }     
                                                </tbody>
                                            )
                                        })
                                }
                            </table>

                            {
                                "id" in selectedPestDiseases === true &&
                                    <>
                                    {
                                        ("id" in selectedPestDiseases || "id" in props.selected_pest_diseases_media) &&
                                            <div className="activityContainer">
                                                <div className="leftOffset"></div>
                                                <div className="mainContent">
                                                    {
                                                        languageLength.map((lang,key)=>{
                                                            return (
                                                                langContent === lang && 
                                                                    <div key={key}>
                                                                    {
                                                                        "id" in selectedCropMedia ?
                                                                            <div>
                                                                                <TextBox 
                                                                                    label="Media Title" 
                                                                                    id="media_title"
                                                                                    name="title"
                                                                                    onTextChange1={textChange1}
                                                                                    value={
                                                                                        getMediaLanguage() in mediaData ?
                                                                                            mediaData[getMediaLanguage()].title
                                                                                        :
                                                                                            "id" in mediaData ? 
                                                                                                key in mediaData ?
                                                                                                    mediaData.media_text[key].title
                                                                                                :
                                                                                                    undefined
                                                                                            :
                                                                                                undefined
                                                                                    }
                                                                                    value1={
                                                                                        languageContent !== 'en_EN' ?
                                                                                            getMediaLanguage1() in mediaData ?
                                                                                                mediaData[getMediaLanguage1()].title
                                                                                            :
                                                                                                "id" in mediaData ? 
                                                                                                    key in mediaData ? 
                                                                                                        mediaData.media_text[key].title
                                                                                                    :
                                                                                                        undefined
                                                                                                :
                                                                                                    undefined
                                                                                        :
                                                                                            undefined
                                                                                    }
                                                                                />
                                                                                <TextArea 
                                                                                    label="Media Description" 
                                                                                    id="media_description"
                                                                                    name="description"
                                                                                    onTextChange1={textChange1}
                                                                                    value={
                                                                                        getMediaLanguage() in mediaData ?
                                                                                            mediaData[getMediaLanguage()].description
                                                                                        :
                                                                                            "id" in mediaData ? 
                                                                                                getMediaLanguage() in mediaData ?
                                                                                                    mediaData.media_text[getMediaLanguage()].description
                                                                                                :
                                                                                                    undefined
                                                                                            :
                                                                                                undefined
                                                                                    }
                                                                                    value1={
                                                                                        languageContent !== 'en_EN' ?
                                                                                            getMediaLanguage1() in mediaData ?
                                                                                                mediaData[getMediaLanguage1()].description
                                                                                            :
                                                                                                "id" in mediaData ? 
                                                                                                    key in mediaData ?
                                                                                                        mediaData.media_text[key].description
                                                                                                    :
                                                                                                        undefined
                                                                                                :
                                                                                                    undefined
                                                                                        :
                                                                                            undefined
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        :
                                                                            <div>
                                                                                <TextBox 
                                                                                    label="Media Title"
                                                                                    id="media_title"
                                                                                    name="title" 
                                                                                    onTextChange1={textChange1}
                                                                                    value={getMediaLanguage() in mediaData ? mediaData[getMediaLanguage()].title : undefined}
                                                                                    value1={
                                                                                        languageContent !== 'en_EN' ?
                                                                                            getMediaLanguage1() in mediaData ? 
                                                                                                mediaData[getMediaLanguage1()].title 
                                                                                            : 
                                                                                                undefined
                                                                                        :
                                                                                            undefined
                                                                                    }
                                                                                /> 
                                                                                <TextArea 
                                                                                    label="Media Description" 
                                                                                    id="media_description"
                                                                                    name="description"
                                                                                    onTextChange1={textChange1}
                                                                                    value={getMediaLanguage() in mediaData ? mediaData[getMediaLanguage()].description : undefined}
                                                                                    value1={
                                                                                        languageContent !== 'en_EN' ?
                                                                                            getMediaLanguage1() in mediaData ? 
                                                                                                mediaData[getMediaLanguage1()].description 
                                                                                            : 
                                                                                                undefined
                                                                                        :
                                                                                            undefined
                                                                                    }
                                                                                /> 
                                                                            </div>
                                                                    }
                                                                    </div>
                                                            )
                                                        })
                                                    }

                                                    <TextBox 
                                                        label="Code" 
                                                        name="code"
                                                        id="code"
                                                        value={code}
                                                        error={codeError === true && "Code is required."}
                                                        onTextChange={textChange1}
                                                    />
                                                
                                                    {
                                                    "id" in selectedCropMedia ?
                                                            <TextBox
                                                                label="Media Link"
                                                                value={
                                                                    "id" in selectedCropMedia ? 
                                                                        getMediaLanguage() in selectedCropMedia.media_text ?
                                                                            selectedCropMedia.media_text[getMediaLanguage()].link
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                }
                                                                disabled={true}
                                                            />
                                                        :
                                                            <TextBox
                                                                label="Media Link"
                                                                disabled={true}
                                                            />
                                                    }

                                                    <div className="TextBoxContainer">
                                                        <div className="leftSelectLabelContainer1">
                                                            <span>Add before</span>
                                                        </div>
                
                                                        <div className="rightSelectLabelContainer1">
                                                            <div style={{display: "flex",flexDirection: 'column'}}>
                                                                <select 
                                                                    className="selectInput"
                                                                    name="sorting"
                                                                    value={
                                                                        Object.keys(selectedCropMedia).length == 0 ?
                                                                           sorting == "" ? 
                                                                            getLastIndex() : sorting
                                                                        :
                                                                        getLastMediaSorting(mediaSorting) < selectedMedia ?
                                                                            parseInt(selectedMedia) + 1
                                                                        :
                                                                            selectedMedia !== "" ?
                                                                                parseInt(selectedMedia) + 1
                                                                            :
                                                                                sorting
                                                                    }
                                                                    onChange={(e)=>{
                                                                        setMediaSortingDropDown(parseInt(e.target.value));
                                                                        textChange1("sorting",parseInt(e.target.value));
                                                                        setSelectedMedia('');
                                                                    }}
                                                                    defaultValue={getLastIndex()}
                                                                >
                                                                    {
                                                                        mediaSorting.map((data,key)=>{
                                                                            return (
                                                                                <option key={key} value={data.id}>{data.value}</option>
                                                                            )
                                                                        })
                                                                    }    
                                                                    {
                                                                        Object.keys(selectedCropMedia).length == 0 ?
                                                                            <option value={getLastIndex()}>{`End`}</option>
                                                                        :
                                                                            getLastMediaSorting(mediaSorting) < selectedMedia ?
                                                                                <option value={getLastIndex()}>{`End`}</option>
                                                                            :
                                                                                parseInt(selectedCropMedia.sorting) + 1 == getLastIndex() ?
                                                                                    <option value={getLastIndex()}>{`End`}</option>
                                                                                :
                                                                                    null
                                                                    } 
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        languageLength.map((lang, key)=>{
                                                            return (
                                                                langContent === lang &&
                                                                    <div key={key}>
                                                                        {
                                                                            langContent in pestDiseasesText === true ?
                                                                                <div className="TextBoxContainer">
                                                                                    <div className="leftSelectLabelContainer1">
                                                                                        <span>Media Type</span>
                                                                                    </div>
                                            
                                                                                    <div className="rightSelectLabelContainer1">
                                                                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                                                                            <select 
                                                                                                className="selectInput"
                                                                                                value={(pestDiseasesText[langContent].mediaType !== "" && pestDiseasesText[langContent].mediaType !== null) ? pestDiseasesText[langContent].mediaType : "NULL"}
                                                                                                name="mediaType"
                                                                                                onChange={(e)=>{
                                                                                                    textChange1("mediaType",e.target.value);
                                                                                                }}
                                                                                            >
                                                                                                <option value={"image/jpeg"}>{`image/jpeg`}</option>
                                                                                                <option value={"video/mp4"}>{`video/mp4`}</option>   
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            :
                                                                                <div className="TextBoxContainer">
                                                                                    <div className="leftSelectLabelContainer1">
                                                                                        <span>Media Type</span>
                                                                                    </div>
                                            
                                                                                    <div className="rightSelectLabelContainer1">
                                                                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                                                                            <select 
                                                                                                className="selectInput"
                                                                                                name="mediaType"
                                                                                                onChange={(e)=>{
                                                                                                    textChange1("mediaType",e.target.value);
                                                                                                }}
                                                                                            >
                                                                                                <option value={"image/jpeg"}>{`image/jpeg`}</option>
                                                                                                <option value={"video/mp4"}>{`video/mp4`}</option> 
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                            <div className="activityContainer">
                                                                <div className="leftOffset"></div>
                                                                <div className="mainContent1">
                                                                    <CustomButton 
                                                                        name={`Save Media Item`}
                                                                        onSaveButtonClick={onAddMoreButtonClick}
                                                                    />
                                                                </div>
                                                            </div>
                                                        :
                                                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                <div className="activityContainer">
                                                                    <div className="leftOffset"></div>
                                                                    <div className="mainContent1">
                                                                        <CustomButton 
                                                                            name={`Save Media Item`}
                                                                            onSaveButtonClick={onAddMoreButtonClick}
                                                                        />
                                                                    </div>
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                    }

                                    {
                                        ("id" in selectedPestDiseases) &&
                                            <table style={{width: "100%"}} className="tableContainer" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Media ID</th>
                                                        <th>Code</th>
                                                        <th>Activity ID</th>
                                                        <th>Pest Diseases ID</th>
                                                        <th>Crop ID</th>
                                                        <th>Sorting</th>
                                                        <th>Media Title</th>
                                                        <th>Media Link</th>
                                                        <th>Media Type</th>
                                                        {
                                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                <>
                                                                    <th>
                                                                        <span>Edit</span> 
                                                                    </th>
                                                                    <th>
                                                                        <span>Delete</span> 
                                                                    </th>
                                                                </>
                                                            :
                                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                    <>
                                                                        <th>
                                                                            <span>Edit</span> 
                                                                        </th>
                                                                        <th>
                                                                            <span>Delete</span> 
                                                                        </th>
                                                                    </>
                                                        }
                                                    </tr>
                                                </thead> 
                                                {
                                                    props.pest_diseases_media_list.length > 0 &&
                                                        props.pest_diseases_media_list.map((media,key)=>{
                                                            return (
                                                                <tbody key={key} className={"id" in media ? media.id === selectedCropMedia.id ? "selectedRow" : "" : ""}>
                                                                    <tr>
                                                                        <td className="boldContent">{media.id}</td>
                                                                        <td className="boldContent">{media.code}</td>
                                                                        <td className="boldContent">{media.activity_id}</td>
                                                                        <td className="boldContent">{media.pestDisease_id}</td>
                                                                        <td className="boldContent">{media.crop_id}</td>
                                                                        <td className="boldContent">{media.sorting}</td>
                                                                        <td className="boldContent">
                                                                            {
                                                                                media.media_text.length > 0 &&
                                                                                    getlanguageFOrPestTable(media.media_text) in media.media_text ?
                                                                                        media.media_text[getlanguageFOrPestTable(media.media_text)].title
                                                                                    :
                                                                                        undefined
                                                                            }
                                                                        </td>
                                                                        <td className="boldContent">
                                                                            {
                                                                                media.media_text.length > 0 &&
                                                                                    getlanguageFOrPestTable(media.media_text) in media.media_text ?
                                                                                        media.media_text[getlanguageFOrPestTable(media.media_text)].link
                                                                                    :
                                                                                        undefined
                                                                            }
                                                                        </td>
                                                                        <td className="boldContent"> 
                                                                            {
                                                                                media.media_text.length > 0 &&
                                                                                    getlanguageFOrPestTable(media.media_text) in media.media_text ?
                                                                                        media.media_text[getlanguageFOrPestTable(media.media_text)].mediaType
                                                                                    :
                                                                                        undefined
                                                                            }
                                                                        </td>
                                                                        {
                                                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                                <>
                                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                                        <img 
                                                                                            src={require('../../../images/edit.svg')} 
                                                                                            alt="edit" 
                                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                            onClick={()=>{
                                                                                                document.getElementById('media_title').value = "";
                                                                                                document.getElementById('media_description').value = "";

                                                                                                document.getElementById('code').value = "";
                                                                                                document.getElementById('code').value = media.code;

                                                                                                setMediaSortingDropDown(media.sorting);
                                                                                                props.actions.selectedPestDiseasesMedia(media);
                                                                                                editPestDiseasesMedia(media);
                                                                                                setSelectedMedia(media.sorting);
                                                                                                setCode(media.code);
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                                        <img 
                                                                                            src={require('../../../images/delete.png')} 
                                                                                            alt="delete" 
                                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                            onClick={()=>{
                                                                                                openModal1();
                                                                                                props.actions.selectedPestDiseasesMedia(media);
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </>
                                                                            :
                                                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&  
                                                                                    <>
                                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                                            <img 
                                                                                                src={require('../../../images/edit.svg')} 
                                                                                                alt="edit" 
                                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                                onClick={()=>{
                                                                                                    document.getElementById('media_title').value = "";
                                                                                                    document.getElementById('media_description').value = "";

                                                                                                    document.getElementById('code').value = "";
                                                                                                    document.getElementById('code').value = media.code;

                                                                                                    setMediaSortingDropDown(media.sorting);
                                                                                                    props.actions.selectedPestDiseasesMedia(media);
                                                                                                    editPestDiseasesMedia(media);
                                                                                                    setSelectedMedia(media.sorting);
                                                                                                    setCode(media.code);
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                                            <img 
                                                                                                src={require('../../../images/delete.png')} 
                                                                                                alt="delete" 
                                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                                onClick={()=>{
                                                                                                    openModal1();
                                                                                                    props.actions.selectedPestDiseasesMedia(media);
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                    </>
                                                                        }
                                                                    </tr>
                                                                    {
                                                                        <tr>
                                                                            <td colSpan="11">
                                                                            {
                                                                                media.media_text.length > 0 &&
                                                                                    getlanguageFOrPestTable(media.media_text) in media.media_text ?
                                                                                        media.media_text[getlanguageFOrPestTable(media.media_text)].description
                                                                                    :
                                                                                        undefined
                                                                            }
                                                                        </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            )
                                                        })
                                                }
                                            </table>
                                    }
                                    {
                                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                            deleteModal()
                                        :
                                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                deleteModal()
                                    }
                                    {
                                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                            renderModal()
                                        :
                                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                renderModal()
                                    }
                                    </>
                            }
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
       lang: state.lang.language,
       pestDiseasesData: state.crop.pestDiseasesData,
       selected_crop_id: state.crop.selected_crop_id,
       pest_diseases_list: state.crop.pest_diseases_list,
       pest_diseases_list_loading: state.crop.pest_diseases_list_loading,
       selected_pest_diseases: state.crop.selected_pest_diseases,
       pest_diseases_media_list: state.crop.pest_diseases_media_list,
       selected_pest_diseases_media: state.crop.selected_pest_diseases_media,
       pest_diseases_loading: state.crop.pest_diseases_loading,
       pest_diseases_media_register_loading: state.crop.pest_diseases_media_register_loading,
       delete_pest_diseases_loading: state.crop.delete_pest_diseases_loading,
       crop_delete: state.crop.crop_delete,
       user_role_type: state.auth.user_role_type,
       language_list: state.lang.language_list,
       organisation: state.auth.organization,
       mapping_list: state.organization.mapping_list,
       auth: state.auth.organization,
       organisation: state.auth.organization,
       superuser: state.auth.superuser,
       umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                pestDiseasesListRequest, 
                setPestDiseases,
                selectPestDiseases,
                pestDiseasesMediaListRequest,
                selectedPestDiseasesMedia,
                registerPestDiseasesMedia,
                deletePestDiseasesRequest,
                deleteMedia,
                registerPestDiseasesRequest,
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);
