import React, {useEffect, useState} from 'react';
import Header from '../../../../common/header';
import Breadcrumb from '../../../../common/breadcrumb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextArea from '../../../../common/textArea';
import TextBox from '../../../../common/textBox';
import CustomButton from '../../../../common/button';
import { 
    setDescriptionData,  
    registerDescription, 
    setMediaData, 
    registerDescriptionMeida,
    cropMediaListRequest,
    selectCropMedia,
    deleteMedia
} from '../../action';

import {
    languageUpdate
} from '../../../../trans/actions';

import './general.css';
import '../../../index.scss';
import Spinner from '../../../../common/spinner';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { GetToOrganisation } from '../../../lib/getToOrganisation';

import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const General = props => {
    const [languages, setLanguages] = useState([]);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [generalData, setGeneralData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [sorting, setSorting] = useState();
    const [data, setData] = useState(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
    const [cropData] = useState(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
    const [selectedCropMedia, setSelectedCropMedia] = useState([]);
    const [checkedData, setCheckedData] = useState([]);
    const [mediaSorting, setMediaSorting] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState('');
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);

    useEffect(()=>{
        let temp_locale = [];
        props.language_list.map((tmp, key)=>{
            temp_locale.push(tmp.locale);

            if(key === Object.values(props.language_list).length - 1){
                setLanguages(temp_locale);
            }
        })
    },[]);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setCheckedData(checkedData);
    },[checkedData]);

    useEffect(()=>{
        setData(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
    },[props,props.selected_crop_id]);

    useEffect(()=>{
        setMediaData([]);
        setSelectedCropMedia([]);
        setMediaSorting([]);
        setCode('');
    },[props.description_loading, props.description_media_loading, props.crop_delete])

    useEffect(()=>{
        setMediaData(mediaData);
    },[mediaData]);

    useEffect(()=>{
        var crop_text = ["locale","description","use","lifeCycle",'soilPerference','fertilizerAdvise','of_unit'];
        if(props.selected_crop_id !== ""){
            if("crop_text" in data){
                let data1 = [];
                data.crop_text.map((crop_data_text)=>{
                    let data2 = {};
                    for (const [key, value] of Object.entries(crop_data_text)) {
                        if(crop_text.includes(key)){
                            if(key === 'use'){
                                data2["cropUse"] = value;
                            }
                            else{
                                data2[key] = value;
                            }
                        }
                    }
                    data1.push(data2);
                    return ()=>{};
                })
                setGeneralData(data1);
            }
        }
    },[]);

    useEffect(()=>{
        if(props.selected_crop_id !== ""){
            props.actions.cropMediaListRequest(props.selected_crop_id, props.organisation);
        }
    },[props.actions,props.selected_crop_id]);

    const getLanguage = (data) => {
        let index = data.findIndex((temp_data)=>{
            return languageContent === temp_data.locale
        })
        return index;
    }

    const textChange = (name, text) => {
        if(name === 'sorting'){
            setSorting(text);
        }
        else{
            let data2 = getLanguageIndex() in generalData ? generalData[getLanguageIndex()]: [];
            
            let description = {"description": ""};
            let cropUse = {"cropUse": ""};
            let lifeCycle = {"lifeCycle": ""};
            let soilPerference = {"soilPerference": ""};
            let fertilizerAdvise =  {"fertilizerAdvise": ""};
            let of_unit =  {"of_unit": ""};
            let data6 = {...data2, ...description, ...cropUse, ...lifeCycle, ...soilPerference, ...fertilizerAdvise, ...of_unit};

            data2[name] = text;
        
            let language = {"locale": languageContent};
            
            let data3 = {...data6, ...data2,    ...language};
            
            let language2 = getLanguageIndex() === -1 ? Object.keys(generalData).length : getLanguageIndex();

            let data4 = {[language2]: data3};
            let data5 = {...generalData, ...data4};
            let data = {...cropData, ...data4};

            props.actions.setDescriptionData(data);
            setGeneralData(data5);
        }
    }

    const textChange1 = (name, text) => {
        if(name !== "code"){
            let data1 = {[name]: text};
            let data2 = getMediaLangauge() in mediaData ? mediaData[getMediaLangauge()] : [];

            let description = "description" in data2 === false && {"description": ""};
            let title = "title" in data2 === false && {"title": ""};
            let mediaType= "mediaType" in data2 === false && {"description": ""};

            let language = {"locale": languageContent};

            let language2 = getMediaLangauge() === -1 ? Object.keys(mediaData).length : getMediaLangauge();
            let data3 = {...description, ...title, ...mediaType, ...data2, ...data1, ...language};
            let data4 = {[language2]: data3};
            let data = {...mediaData, ...data4};

            setMediaData(data);
        }
        else{
            setCode(text);
            setCodeError(false);
        }
    }

    const onSaveButtonClick = () => { 
        let data1 = {"crop_id": props.selected_crop_id };
        let data2 = {"cropGeneral":generalData};
        let data3 = {"organisation": props.organisation}
        let data = {...data1, ...data2, ...data3};
        
        props.actions.registerDescription(data);
    }

    const onAddMoreButtonClick = () => {
        let media_id = Object.keys(mediaData).length > 0 ? "media_id" in mediaData[0] ? {"media_id": mediaData[0].media_id} : {} : {};
        let data1 = {"crop_id": props.selected_crop_id };
        let data2 = {"sorting": sorting === undefined ? getLastIndex() : sorting};

        if(code === ""){
            setCodeError(true);
        }
        else{
            setCodeError(false);
            if(Object.keys(mediaData).length > 0){
                let added_language = languages;
    
                let media_type = [];
                
                for(let i = 0; i<added_language.length ; i++){

                    let tempData = [];
                    tempData.title = i in mediaData ? mediaData[i].title : "";
                    tempData.description = i in mediaData ? mediaData[i].description : "";
                    tempData.mediaType = "image/jpeg";
                    tempData.locale = i in mediaData ? mediaData[i].locale : added_language[0]
                    media_type.push(tempData);
                }
                let data3 = {"mediaText": media_type};
                let data4 = {"organisation": props.organisation}
                let data5 = {"code": code};
                let data = {...media_id, ...data1, ...data2, ...data3, ...data4, ...data5};
            
                props.actions.registerDescriptionMeida(data);
            }
        }
    }

    useEffect(()=>{
        if("id" in selectedCropMedia){
            let crop_text_data = [];

            let data = {};
            for (const [key, value] of Object.entries(selectedCropMedia)) {
                if(key === 'sorting'){
                    data = value;
                }
            }
            setSorting(data);

            selectedCropMedia.media_text.map((crop_data_text)=>{
                let data2 = {};
                for (const [key, value] of Object.entries(crop_data_text)) {
                    data2[key] = value;
                }
                crop_text_data.push(data2);
            });
            setMediaData(crop_text_data);
        }
    },[selectedCropMedia]);

    const getLanguageIndex = () => {
        let data0 = Object.values(generalData).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return data0;
    }

    const getLanguage1 = () => {
        let data0 = Object.values(generalData).findIndex((temp_data)=>{return temp_data.locale === 'en_EN'});
        return data0; 
    }

    const getMediaLangauge = () =>{
        let data0 = Object.values(mediaData).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return data0;
    }
    const getMediaLanguage1 = () => {
        let data0 = Object.values(mediaData).findIndex((temp_data)=>{return temp_data.locale === 'en_EN'});
        return data0;
    }
    
    useEffect(()=>{
        if(props.crop_media_list.length > 0){
            let data0 = [];

            props.crop_media_list.map((media_list)=>{
                var defaultLanguageExists = Object.values(media_list.media_text).filter((tempData)=>{return tempData.locale.includes('en_EN') == true});
                let data = [];
                media_list.media_text.map((temp_data)=>{
                    let data1 = [];
                    if(Object.keys(defaultLanguageExists).length > 0){
                        if(defaultLanguageExists[0].locale == temp_data.locale){
                            if(Object.keys(selectedCropMedia).length > 0){
                                if(selectedCropMedia.id != media_list.id){
                                    data1.id = media_list.sorting;
                                    data1.value = temp_data.title;
                                    data = data1;
                                    data0.push(data);
                                }
                            }
                            else{
                                data1.id = media_list.sorting;
                                data1.value = temp_data.title;
                                data = data1;
                                data0.push(data);
                            }
                        }
                    }
                    else{
                        if(Object.keys(selectedCropMedia).length > 0){
                            if(selectedCropMedia.id != media_list.id){
                                data1.id = media_list.sorting;
                                data1.value = temp_data.title;
                                data = data1;
                                data0.push(data);
                            }
                        }
                        else{
                            data1.id = media_list.sorting;
                            data1.value = temp_data.title;
                            data = data1;
                            data0.push(data);
                        }
                    }
                });
            });
            setMediaSorting(data0);
        }
    },[props.crop_media_list, selectedCropMedia]); 

    const getLastIndex  = () =>{
        if(props.crop_media_list.length > 0 ){
            let sort = props.crop_media_list.sort((a,b) => (a.sorting > b.sorting) ? 1 : -1 );
            if(sort.length > 0){
                return sort[sort.length - 1].sorting + 1;
            }
            else{
                return 0;
            }
        }
        else{
           return 0; 
        }
    }

    const editGeneralMedia = (media) => {
        document.getElementById('media_title').value = langContent in media.media_text ? media.media_text[langContent].title : "";
        document.getElementById('description').value = langContent in media.media_text ? media.media_text[langContent].description : "";
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const getLastMediaSorting = (mediaSortingData) => {
        if(mediaSortingData.length > 0)
            return mediaSortingData[mediaSortingData.length - 1].id;
        else    
            return 0;
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected Media ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal();
                                props.actions.deleteMedia(selectedCropMedia.id, "general", props.organisation);
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div>
            <Header header={false} />

            <Breadcrumb />

            <div className="generalBody"> 
                <ToastContainer/>
                {
                    (props.description_loading === true || props.description_media_loading === true || props.crop_delete === true) ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column'}}>
                        {
                            languageLength.map((lang,key1)=>{
                                return(
                                    langContent === lang &&
                                        <div key={key1}>
                                        {
                                            "crop_text" in data ?
                                                <div key={key1}>
                                                    <TextArea
                                                        label="Crop Description"
                                                        id="crop_description"
                                                        name="description"
                                                        onTextChange={textChange} 
                                                        value={
                                                            getLanguageIndex() in generalData ? 
                                                                generalData[getLanguageIndex()].description
                                                            :
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_text.length > 0 ?
                                                                        "description" in data.crop_text ?
                                                                            data.crop_text[key1].description
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                        }
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? 
                                                                    generalData[getLanguage1()].description
                                                                :
                                                                    props.selected_crop_id !== "" ? 
                                                                        data.crop_text.length > 0 ?
                                                                            "description" in data.crop_text ?
                                                                                data.crop_text[key1].description
                                                                            : 
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                    <TextArea
                                                        label="Use of Crop"
                                                        id="use_of_crop"
                                                        name="cropUse"
                                                        onTextChange={textChange}
                                                        value={
                                                            getLanguageIndex() in generalData ? 
                                                                generalData[getLanguageIndex()].cropUse
                                                            :
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_text.length > 0 ?
                                                                        "use" in data.crop_text ?
                                                                            data.crop_text[key1].use 
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                        }
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ?
                                                                    generalData[getLanguage1()].cropUse
                                                                :   
                                                                    props.selected_crop_id !== "" ? 
                                                                        data.crop_text.length > 0 ?
                                                                            "use" in data.crop_text ?
                                                                                data.crop_text[0].use
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }
                                                    />

                                                    <TextArea
                                                        label="Crop Lifecycle"
                                                        id="crop_lifecycle"
                                                        name="lifeCycle"
                                                        onTextChange={textChange}
                                                        value={
                                                            getLanguageIndex() in generalData ? 
                                                                generalData[getLanguageIndex()].lifeCycle
                                                            :
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_text.length > 0 ?
                                                                        "lifeCycle" in data.crop_text ?
                                                                            data.crop_text[key1].lifeCycle
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                        }
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ?
                                                                    generalData[getLanguage1()].lifeCycle
                                                                :
                                                                    props.selected_crop_id !== "" ? 
                                                                        data.crop_text.length > 0 ?
                                                                            "lifeCycle" in data.crop_text ?
                                                                                data.crop_text[0].lifeCycle
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                    <TextArea
                                                        label="Soil Preference"
                                                        id="soil_preference"
                                                        name="soilPerference"
                                                        onTextChange={textChange}
                                                        value={
                                                            getLanguageIndex() in generalData ? 
                                                                generalData[getLanguageIndex()].soilPerference
                                                            :
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_text.length > 0 ?
                                                                        "soilPreference" in data.crop_text ?
                                                                            data.crop_text[key1].soilPerference
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                        }
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ?
                                                                    generalData[getLanguage1()].soilPerference
                                                                :
                                                                    props.selected_crop_id !== "" ? 
                                                                        data.crop_text.length > 0 ?
                                                                            "soilPreference" in data.crop_text ?
                                                                                data.crop_text[key1].soilPerference
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                    <TextArea
                                                        label="Fertilizer Advice"
                                                        id="fertilizer_advice"
                                                        name="fertilizerAdvise"
                                                        onTextChange={textChange}
                                                        value={ 
                                                            getLanguageIndex() in generalData ? 
                                                                generalData[getLanguageIndex()].fertilizerAdvise
                                                            :
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_text.length > 0 ?
                                                                        "fertilizerAdvise" in data.crop_text ?
                                                                            data.crop_text[key1].fertilizerAdvise 
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                        }
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? 
                                                                    generalData[getLanguage1()].fertilizerAdvise
                                                                :
                                                                    props.selected_crop_id !== "" ? 
                                                                        data.crop_text.length > 0 ?
                                                                            "fertilizerAdvise" in data.crop_text ?
                                                                                data.crop_text[key1].fertilizerAdvise
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                </div>
                                            :
                                                <div>
                                                    <TextArea
                                                        label="Crop Description"
                                                        id="crop_description"
                                                        name="description"
                                                        onTextChange={textChange}
                                                        value={getLanguageIndex() in generalData ? generalData[getLanguageIndex()].description : undefined}
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? generalData[getLanguage1()].description : undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                    <TextArea
                                                        label="Use of Crop"
                                                        id="use_of_crop"
                                                        name="cropUse"
                                                        onTextChange={textChange}
                                                        value={getLanguageIndex() in generalData ? generalData[getLanguageIndex()].cropUse : undefined}
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? generalData[getLanguage1()].cropUse : undefined
                                                            :
                                                                undefined
                                                        }
                                                    />    
                                                    <TextArea
                                                        label="Crop Lifecycle"
                                                        id="crop_lifecycle"
                                                        name="lifeCycle"
                                                        onTextChange={textChange}
                                                        value={getLanguageIndex() in generalData ? generalData[getLanguageIndex()].lifeCycle : undefined}
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? generalData[getLanguage1()].lifeCycle : undefined
                                                            :
                                                                undefined
                                                        }
                                                    />   
                                                    <TextArea
                                                        label="Soil Preference"
                                                        id="soil_preference"
                                                        name="soilPerference"
                                                        onTextChange={textChange}
                                                        value={getLanguageIndex() in generalData ? generalData[getLanguageIndex()].soilPerference : undefined}
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? generalData[getLanguage1()].soilPerference : undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                    <TextArea
                                                        label="Fertilizer Advice"
                                                        id="fertilizer_advice"
                                                        name="fertilizerAdvise"
                                                        onTextChange={textChange}
                                                        value={getLanguageIndex() in generalData ? generalData[getLanguageIndex()].fertilizerAdvise : undefined}
                                                        value1={
                                                            languageContent !== 'en_EN' ?
                                                                getLanguage1() in generalData ? generalData[getLanguage1()].fertilizerAdvise : undefined
                                                            :
                                                                undefined
                                                        }
                                                    />
                                                </div>
                                            }
                                        </div>
                                )
                            })
                        }  

                        {
                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                    <div className="leftSelectLabelContainer1"></div>
                                    
                                    <div className="rightSelectLabelContainer1">
                                        <CustomButton
                                            name={`Save General`} 
                                            onSaveButtonClick={onSaveButtonClick}
                                        />
                                    </div>
                                </div>
                            :
                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                    <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                        <div className="leftSelectLabelContainer1"></div>
                                        
                                        <div className="rightSelectLabelContainer1">
                                            <CustomButton
                                                name={`Save General`} 
                                                onSaveButtonClick={onSaveButtonClick}
                                            />
                                        </div>
                                    </div>
                        }

                        <div className="activityContainer">
                            <div className="leftOffset1"></div>

                            <div className="mainContent">
                                {
                                    languageLength.map((lang,key)=>{
                                        return(
                                            langContent === lang &&
                                                <div key={key}>
                                                    {
                                                        "id" in selectedCropMedia ?
                                                            <div>
                                                                <TextArea
                                                                    label="Media Title"
                                                                    name="title"
                                                                    id="media_title"
                                                                    onTextChange1={textChange1}
                                                                    value={
                                                                        getMediaLangauge() in mediaData ?
                                                                            mediaData[getMediaLangauge()].title
                                                                        :
                                                                            "id" in selectedCropMedia ?
                                                                                key in selectedCropMedia ?
                                                                                    selectedCropMedia.media_text[key].title
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    
                                                                    value1={
                                                                        languageContent !== 'en_EN' ?
                                                                            getMediaLanguage1() in mediaData ?
                                                                                mediaData[getMediaLanguage1()].title
                                                                            :
                                                                                "id" in selectedCropMedia ?
                                                                                    key in selectedCropMedia ?
                                                                                        selectedCropMedia.media_text[key].title
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                /> 
                                                                
                                                                <TextArea
                                                                    label="Description"
                                                                    name="description"
                                                                    id="description"
                                                                    onTextChange1={textChange1}
                                                                    value={
                                                                        getMediaLangauge() in mediaData ?
                                                                            mediaData[getMediaLangauge()].description
                                                                        :
                                                                            "id" in selectedCropMedia ?
                                                                                key in selectedCropMedia ?
                                                                                    selectedCropMedia.media_text[key].description
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    value1={
                                                                        languageContent !== 'en_EN' ?
                                                                            getMediaLanguage1() in mediaData ?
                                                                                mediaData[getMediaLanguage1()].description
                                                                            :
                                                                                "id" in selectedCropMedia ?
                                                                                    key in selectedCropMedia ?
                                                                                        selectedCropMedia.media_text[key].description
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                /> 
                                                            </div>
                                                        :
                                                            <div>
                                                                <TextArea
                                                                    label="Media Title"
                                                                    id="media_title"
                                                                    name="title"
                                                                    onTextChange1={textChange1}
                                                                    value={getMediaLangauge() in mediaData ? mediaData[getMediaLangauge()].title : undefined}
                                                                    value1={
                                                                        languageContent !== 'en_EN' ?
                                                                            getMediaLanguage1() in mediaData ? mediaData[getMediaLanguage1()].title : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                                <TextArea
                                                                    label="Description"
                                                                    id="description"
                                                                    name="description"
                                                                    onTextChange1={textChange1}
                                                                    value={getMediaLangauge() in mediaData ? mediaData[getMediaLangauge()].description : undefined}
                                                                    value1={
                                                                        languageContent !== 'en_EN' ?
                                                                            getMediaLanguage1() in mediaData ? mediaData[getMediaLanguage1()].description : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                        )
                                    })
                                }   

                                <TextBox 
                                    label="Code" 
                                    name="code"
                                    id="code"
                                    value={code}
                                    error={codeError === true && "Code is required."}
                                    onTextChange={textChange1}
                                />

                                {
                                    "id" in selectedCropMedia ?
                                        <TextBox
                                            label="Media link"
                                            value={
                                                getMediaLangauge() in mediaData ?
                                                    mediaData[getMediaLangauge()].link
                                                :
                                                    undefined
                                            }
                                            disabled={true}
                                        />
                                    :
                                        <TextBox
                                            label="Media link"
                                            disabled={true}
                                        />
                                }

                               <div className="TextBoxContainer">
                                    <div className="leftSelectLabelContainer1">
                                        <span>Add before</span>
                                    </div>

                                    <div className="rightSelectLabelContainer1">
                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                            <select 
                                                className="selectInput"
                                                name="sorting"
                                                value={
                                                    Object.keys(selectedCropMedia).length == 0 ?
                                                        sorting
                                                    :
                                                    getLastMediaSorting(mediaSorting) < selectedMedia ?
                                                        parseInt(selectedMedia) + 1
                                                    :
                                                        selectedMedia !== "" ?
                                                            parseInt(selectedMedia) + 1
                                                        :
                                                            sorting
                                                }
                                                onChange={(e)=>{
                                                    setSelectedMedia(parseInt(e.target.value));
                                                    textChange("sorting",parseInt(e.target.value));
                                                    setSelectedMedia('')
                                                }}
                                                defaultValue={getLastIndex()}
                                            >
                                                {
                                                    mediaSorting.map((data,key)=>{
                                                        return (
                                                            <option key={key} value={data.id}>{data.value}</option>
                                                        )
                                                    })
                                                }   
                                                {
                                                    Object.keys(selectedCropMedia).length == 0 ?
                                                        <option value={getLastIndex()}>{`End`}</option>
                                                    :
                                                        getLastMediaSorting(mediaSorting) < selectedMedia ?
                                                            <option value={getLastIndex()}>{`End`}</option>
                                                        :
                                                            parseInt(selectedCropMedia.sorting) + 1 == getLastIndex() ?
                                                                <option value={getLastIndex()}>{`End`}</option>
                                                            :
                                                                null
                                                } 
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <TextBox
                                    label="Media Type"
                                    name="mediaType"
                                    value={"image/jpeg"}
                                    disabled={true}
                                />
                            
                                {
                                    (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                        <div className="activityContainer">
                                            <div className="leftOffset"></div>
                                            <div className="mainContent1">
                                                <CustomButton 
                                                    name={`Save & Add More Media`}  
                                                    onSaveButtonClick={onAddMoreButtonClick}
                                                />
                                            </div>
                                        </div>
                                    :
                                        (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                            <div className="activityContainer">
                                                <div className="leftOffset"></div>
                                                <div className="mainContent1">
                                                    <CustomButton 
                                                        name={`Save & Add More Media`}  
                                                        onSaveButtonClick={onAddMoreButtonClick}
                                                    />
                                                </div>
                                            </div>
                                }
                            </div>
                        </div>

                        <table className="tableContainer" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Code</th>
                                    <th>Media Title</th>
                                    <th>Media Description</th>
                                    <th>Media Link</th>
                                    <th>Media Sorting</th>
                                    <th>Media Type</th>
                                    {
                                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                            <>
                                                <th>
                                                    <span>Edit</span> 
                                                </th>
                                                <th>
                                                    <span>Delete</span> 
                                                </th>
                                            </>
                                        :
                                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                <>
                                                    <th>
                                                        <span>Edit</span> 
                                                    </th>
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                                </>
                                    }
                                </tr>
                            </thead> 
                            {
                                props.crop_media_list.length > 0 &&
                                    props.crop_media_list.map((media,key)=>{
                                        return (
                                            <tbody key={key} className={"id" in selectedCropMedia ? selectedCropMedia.id === media.id ? "selectedRow" : "" : ""}>
                                                <tr>
                                                    <td>{media.id}</td>
                                                    <td>{media.code}</td>
                                                    <td>
                                                        {
                                                            media.media_text.length > 0 &&
                                                                getLanguage(media.media_text) in media.media_text &&
                                                                    media.media_text[getLanguage(media.media_text)].title
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            media.media_text.length > 0 &&
                                                                getLanguage(media.media_text) in media.media_text &&
                                                                    media.media_text[getLanguage(media.media_text)].description
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            media.media_text.length > 0 &&
                                                                getLanguage(media.media_text) in media.media_text &&
                                                                    media.media_text[getLanguage(media.media_text)].link
                                                        }
                                                    </td>
                                                    <td>{media.sorting}</td>
                                                    <td>
                                                        {
                                                            media.media_text.length > 0 &&
                                                                getLanguage(media.media_text) in media.media_text &&
                                                                    media.media_text[getLanguage(media.media_text)].mediaType
                                                        }
                                                    </td>
                                                    {
                                                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                            <>
                                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                    <img 
                                                                        src={require('../../../../images/edit.svg')} 
                                                                        alt="edit" 
                                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                                        onClick={()=>{
                                                                            document.getElementById('media_title').value = "";
                                                                            document.getElementById('description').value = "";

                                                                            document.getElementById('code').value = "";
                                                                            document.getElementById('code').value = media.code;
                                                                            setCode(media.code);
                                                                            setSelectedMedia(media.sorting);
                                                                            setSelectedCropMedia(media);
                                                                            editGeneralMedia(media);
                                                                        }}
                                                                    /> 
                                                                </td>
                                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                    <img 
                                                                        src={require('../../../../images/delete.png')} 
                                                                        alt="delete" 
                                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                                        onClick={()=>{
                                                                            setSelectedCropMedia(media);
                                                                            openModal();
                                                                        }}
                                                                    />
                                                                </td>
                                                            </>
                                                        :
                                                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                <>
                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                        <img 
                                                                            src={require('../../../../images/edit.svg')} 
                                                                            alt="edit" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                document.getElementById('media_title').value = "";
                                                                                document.getElementById('description').value = "";

                                                                                document.getElementById('code').value = "";
                                                                                document.getElementById('code').value = media.code;
                                                                                setCode(media.code);
                                                                                setSelectedMedia(media.sorting);
                                                                                setSelectedCropMedia(media);
                                                                                editGeneralMedia(media);
                                                                            }}
                                                                        /> 
                                                                    </td>
                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                        <img 
                                                                            src={require('../../../../images/delete.png')} 
                                                                            alt="delete" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                setSelectedCropMedia(media);
                                                                                openModal();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>   
                                                    }
                                                </tr>
                                            </tbody>
                                        )
                                    })
                            }
                        </table>
                        {
                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                renderModal()
                            :
                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                    renderModal()
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        selected_crop_detail: state.crop.selected_crop_detail,
        selected_crop_id: state.crop.selected_crop_id,
        selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
        lang: state.lang.language,
        season_list: state.crop.season_list,
        grow_area_list: state.crop.grow_area_list,
        descriptionData: state.crop.descriptionData,
        mediaData: state.crop.mediaData,
        crop_media_list: state.crop.crop_media_list,
        selected_crop_media: state.crop.selected_crop_media,
        description_loading: state.crop.description_loading,
        crop_media_list_loading: state.crop.crop_media_list_loading,
        description_media_loading: state.crop.description_media_loading,
        crop_delete: state.crop.crop_delete,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        organisation: state.auth.organization,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {   
                setDescriptionData,
                registerDescription,
                setMediaData,
                registerDescriptionMeida,
                cropMediaListRequest,
                selectCropMedia,
                deleteMedia,
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(General);
