import OrganizationActionTypes from './actionTypes';

import { fork, take, call, put } from "redux-saga/effects";

import { OrganizationService } from "./service";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function* organizationRequest() {
    while (true) {
        yield take(OrganizationActionTypes.ORGANIZATION_REQUEST);

        let country = localStorage.getItem('country');

        var organization_list = yield call(OrganizationService.organizationRequest, {country_code: country});

        if (organization_list) {
            yield put({
                type: OrganizationActionTypes.ORGANIZATION_SUCCESS,
                organization_list: organization_list
            })
            localStorage.setItem("organization", JSON.stringify(organization_list));
        }
        else {
            yield put({
                type: OrganizationActionTypes.ORGANIZATION_SUCCESS,
                organization_list: []
            })
        }
    }
}

export function* organizationAddRequest() {
    while (true) {
        const { params } = yield take(OrganizationActionTypes.ORGANIZATION_ADD_REQUEST);

        var add_organization = yield call(OrganizationService.organizationAddRequest, params);

        if (add_organization === "Data has been saved.") {
            var organization_list = yield call(OrganizationService.organizationRequest);

            if (organization_list) {
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_LIST_SUCCESS,
                    organization_list: organization_list
                })
                localStorage.setItem("organization", JSON.stringify(organization_list));

                toast.success("Organisation has been added or updated successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_ADD_SUCCESS
                })
            }
            else {
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_LIST_FAILURE,
                })
            } 
        }
        else {
            if ("status" in add_organization == true) {
                toast.error(add_organization.error.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_ADD_SUCCESS
                })
            }
        }
    }
}

export function* deleteOrganisationRequest() {
    while (true) {
        const { org_id } = yield take(OrganizationActionTypes.ORGANIZATION_DELETE_REQUEST);

        var delete_organization = yield call(OrganizationService.deleteOrganisationRequest, org_id);

        if (delete_organization === "Selected rows has been deleted.") {
            var organization_list = yield call(OrganizationService.organizationRequest);

            if (organization_list) {
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_LIST_SUCCESS,
                    organization_list: organization_list
                })
                localStorage.setItem("organization", JSON.stringify(organization_list));

                toast.success("Organisation has deleted successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_DELETE_SUCCESS
                })
            }
            else {
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_LIST_FAILURE,
                })
            }
        }
        else {
            if ("status" in delete_organization == true) {
                toast.error(delete_organization.error.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                yield put({
                    type: OrganizationActionTypes.ORGANIZATION_DELETE_SUCCESS
                })
            }
        }
    }
}

export function* selectOrganisationRequest() {
    while (true) {
        const { org } = yield take(OrganizationActionTypes.ORGANIZATION_SELECT_REQUEST);

        var selected_organisation = yield call(OrganizationService.getparticularorganisationDetail, { "org_id": org.id });

        if (selected_organisation.length > 0) {
            let data = {};
            let tmp_locale = [];
            let tmp_video_locale = [];
            let mapping = [];
            let ids = [];
            let to_organisation = "";

            selected_organisation.map((tmp) => {
                to_organisation = tmp.organisation;
                tmp.locale = tmp.locale === "rn_RN" ? "rn_KI" : tmp.locale;

                mapping.push({ [tmp.locale]: tmp.videolocale });

                ids.push({ [tmp.locale]: tmp.settingsLocale_id });

                if (tmp_locale.includes(tmp.locale) === false) {
                    tmp_locale.push(tmp.locale);
                }
                if (tmp_video_locale.includes(tmp.videolocale) === false) {
                    tmp_video_locale.push(tmp.videolocale)
                }
            })

            data.id = org.id;
            data.default_locale = selected_organisation[0].defaultlocale;
            data.organisation = org.organisation;
            data.country = "BI";
            data.locale = tmp_locale;
            data.video_locale = tmp_video_locale;
            data.mapping = mapping;
            data.ids = ids;
            data.latitude = selected_organisation[0].latitude;
            data.longitude = selected_organisation[0].longitude;
            data.to_organisation = to_organisation;

            yield put({
                type: OrganizationActionTypes.ORGANIZATION_SELECT_SUCCESS,
                selected_organization: data
            })
        }
    }
}

export function* organizationListRequest() {
    while (true) {
        yield take(OrganizationActionTypes.ORGANIZATION_OFFLINE_REQUEST);

        yield put({
            type: OrganizationActionTypes.ORGANIZATION_OFFLINE_SUCCESS
        })
    }
}

export function* countriesListRequest() {
    while (true) {
        yield take(OrganizationActionTypes.COUNTRY_LIST_REQUEST);

        var countries = yield call(OrganizationService.countriesListRequest);

        yield put({
            type: OrganizationActionTypes.COUNTRY_LIST_SUCCESS,
            countries: countries
        })
    }
}

export function* organisationListRequest() {
    while (true) {
        yield take(OrganizationActionTypes.ORGANISATION_LIST_REQUEST);

        var organisations = yield call(OrganizationService.organisationListRequest);

        if (organisations.result.length > 0) {
            yield put({
                type: OrganizationActionTypes.ORGANISATION_LIST_SUCCESS,
                organisation_list: organisations.result
            })
        }
        else {
            yield put({
                type: OrganizationActionTypes.ORGANISATION_LIST_SUCCESS,
                organisation_list: []
            })
        }
    }
}

export function* selectOrganisationLocaleRequest() {
    while (true) {
        const { org } = yield take(OrganizationActionTypes.ORGANISATION_LOCALE_REQUEST);

        var selected_organisation = yield call(OrganizationService.getparticularorganisationDetail, { "org_id": org.id });

        if (selected_organisation.length > 0) {
            let data = {};
            let tmp_locale = [];
            let tmp_video_locale = [];
            let mapping = [];
            let ids = [];

            selected_organisation.map((tmp) => {
                tmp.locale = tmp.locale === "rn_RN" ? "rn_KI" : tmp.locale;

                mapping.push({ [tmp.locale]: tmp.videolocale });

                ids.push({ [tmp.locale]: tmp.settingsLocale_id });

                if (tmp_locale.includes(tmp.locale) === false) {
                    tmp_locale.push(tmp.locale);
                }
                if (tmp_video_locale.includes(tmp.videolocale) === false) {
                    tmp_video_locale.push(tmp.videolocale)
                }
            })

            data.default_locale = selected_organisation[0].defaultlocale;
            data.country = "BI";
            data.locale = tmp_locale;
            data.video_locale = tmp_video_locale;
            data.mapping = mapping;
            data.ids = ids;
            data.latitude = selected_organisation[0].latitude;
            data.longitude = selected_organisation[0].longitude;

            yield put({
                type: OrganizationActionTypes.ORGANISATION_LOCALE_SUCCESS,
                selected_organization: data
            })
        }
    }
}

export function* mappingListRequest() {
    while (true) {
        yield take(OrganizationActionTypes.ORAGNISATION_MAPPING_LIST_REQUEST);

        var mapping_list = yield call(OrganizationService.mappingListRequest);

        if (mapping_list.length > 0) {
            yield put({
                type: OrganizationActionTypes.ORAGNISATION_MAPPING_LIST_SUCCESS,
                mapping_list: mapping_list
            })
        }
    }
}

export function* countryRequest() {
    while(true){
        yield take(OrganizationActionTypes.COUNTRY_REQUEST);

        var countries = yield call(OrganizationService.countryRequest);

        if(countries){
            if (countries.length > 0) {
                yield put({
                    type: OrganizationActionTypes.COUNTRY_SUCCESS,
                    country: countries
                })
            }
            else{
                yield put({
                    type: OrganizationActionTypes.COUNTRY_SUCCESS,
                    country: []
                })
            }
        }
        else{
            yield put({
                type: OrganizationActionTypes.COUNTRY_SUCCESS,
                country: []
            })
        }
    }
}

export default function* OrganizationSaga() {
    yield fork(organizationRequest);
    yield fork(organizationAddRequest);
    yield fork(deleteOrganisationRequest);
    yield fork(selectOrganisationRequest);
    yield fork(organizationListRequest);
    yield fork(countriesListRequest);
    yield fork(organisationListRequest);
    yield fork(selectOrganisationLocaleRequest);
    yield fork(mappingListRequest);
    yield fork(countryRequest);
}



