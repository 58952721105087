import React, {useState, useEffect} from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { watchCountReportRequest } from '../action';

import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

import '../../crop/view/index.css';

import Spinner from '../../../common/spinner';

import { CSVLink } from "react-csv";

import CustomButton from '../../../common/button';
import moment from 'moment';

const WatchCountReport = (props) => {
    let {watch_count_loading, watch_count } = props.report;
    let {organization} = props.auth;

    const [fromData, setFromDate] = useState("");
    const [toDate, setToData] = useState("");
    const [error, setError] = useState({from: "", to: ""});
    const [downloadLink, setDownloadLink] = useState(false);

    let headers = [
        { label: "UMVA ID", key: "user" },
        { label: "Device ID", key: "device_id" },
        { label: "Country", key: "country" },
        { label: "Province", key: "province" },
        { label: "Commune", key: "commune" },
        { label: "Zone", key: "zone" },
        { label: "Colline", key: "colline" },
        { label: "Locality", key: "locality" },
        { label: "Group", key: "group" },
        { label: "Latitude", key: "latitude" },
        { label: "Longitude", key: "longitude" },
        { label: "Organization", key: "organisation" },
        { label: "Timestamp", key: "created_at" },
        { label: "Guide", key: "usage_title" },
        { label: "Activity", key: "activity_title" },
        { label: "Crop", key: "crop_title" },
        { label: "Pest Disease", key: "pest_title" },
        { label: "Media Link", key: "mediaLink" },
        { label: "Watch Count", key: "watch_count" },
    ];

    useEffect(()=>{
        watch_count = [];
    },[]);

    useEffect(()=>{
        if(Object.values(watch_count).length > 0){
         setDownloadLink(true);
        }
     },[watch_count]);

    const handleSubmit = () => {
        setError({from: "", to: ""});

        if(fromData === "" && toDate === ""){
            setError({
                "from"  :   "From Date is required.",
                "to"    :   "To Date is required."
            });
        }
        else if(fromData === ""){
            setError({
                "from"  :   "From Date is required.",
                "to"    :   ""
            });
        }
        else if(toDate === ""){
            setError({
                "from"  :   "",
                "to"    :   "To Date is required."
            });
        }
        else{
            let _from = new Date(fromData).getTime();
            let _to = new Date(toDate).getTime();

            if(_from > _to) {
                setError({
                    "from"  :   "From Date must be greater than To Date",
                    "to"    :   ""
                });
            }
            else{
                props.actions.watchCountReportRequest({'from': fromData, 'to': toDate, 'organisation': organization});
            }   
        }   
    }

    return (
        <div className="characteristicsContainer">
            <Header header={false} displayLanguage={true} />

            <Breadcrumb />

            <div className="generalBody" style={{flexDirection: 'column'}}>
                <ToastContainer/>

                {
                    watch_count_loading ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column', flex: 1}}>
                            <div className="TextBoxContainer">
                                <div className="leftSelectLabelContainer1">
                                    <span>From</span>
                                </div>

                                <div className="rightSelectLabelContainer1" style={{flexDirection: 'column'}}>
                                    {
                                        Object.values(error).length > 0 &&
                                            <span className="errorText">{error.from}</span>
                                    }
                                    <input 
                                        type="date"
                                        className="textBoxInput"
                                        onChange={(e)=>{
                                            setError({...error, from: ""});
                                            setFromDate(e.target.value);
                                        }}
                                        max={new moment().format("YYYY-MM-DD")}
                                    />
                                </div>
                            </div>

                            <div className="TextBoxContainer">
                                <div className="leftSelectLabelContainer1">
                                    <span>To</span>
                                </div>

                                <div className="rightSelectLabelContainer1" style={{flexDirection: 'column'}}>
                                    {
                                        Object.values(error).length > 0 &&
                                            <span className="errorText">{error.to}</span>
                                    }
                                    <input 
                                        type="date"
                                        className="textBoxInput"
                                        onChange={(e)=>{
                                            setError({...error, to: ""});
                                            setToData(e.target.value);
                                        }}
                                        max={new moment().format("YYYY-MM-DD")}
                                    />
                                </div>
                            </div>

                            <div style={{display: 'flex', flex: 1 ,alignItems: 'center',justifyContent:'center'}}>
                                <div className="leftSelectLabelContainer1"></div>
                                
                                <div className="rightSelectLabelContainer1">
                                    {
                                        error.from === "" && error.to === "" &&
                                            <CustomButton
                                                name={`Download`} 
                                                onSaveButtonClick={handleSubmit}
                                            />
                                    }
                                    {
                                        downloadLink &&
                                            <CSVLink 
                                                data={watch_count}
                                                headers={headers} 
                                                filename={`watch_count_report_from${fromData}_to${toDate}`}
                                                enclosingCharacter={``}
                                            >
                                                Download Watch Count Report
                                            </CSVLink>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        report: state.report,
        auth: state.auth,
    };
};

function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                watchCountReportRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(WatchCountReport);