import React, {useEffect, useState} from 'react';
import Header from '../../../../common/header';
import Breadcrumb from '../../../../common/breadcrumb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../../common/textBox';
import FileType from '../../../../common/fileType';
import SelectBox from '../../../../common/selectBox';
import '../index.css';
import { cropRegisterRequest } from '../../action';
import Spinner from '../../../../common/spinner'; 
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { languageUpdate } from '../../../../trans/actions';
import CustomButton from '../../../../common/button';
import { seasonListRequest } from '../../../season/action';
import GetLatitudeLongitude from "../../../../common/getLatitudeLongitude";
import { GetToOrganisation  } from '../../../lib/getToOrganisation';
import { setLatitudeLongitudeRequest } from '../../action';

const CropGeneral = props => {
    var image_type = ["image/png", "image/jpeg"];
    var levels = [
        {
            label: 'Species',
            value: 'species'
        },
        {
            label: 'Type',
            value: 'type'
        },
        {
            label: 'Variety',
            value: 'variety'
        }
    ];

    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    var language_locale = [];
    props.language_list.map((tempData)=>{
        language_locale.push(tempData.locale);
    });

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [data, setData] = useState(
        props.selected_crop_id !== "" ? 
            props.selected_crop_detail[0] === undefined ? [] : props.selected_crop_detail[0]
        : []
    );
    const [cropText, setCropText] = useState([]);
    const [growAreaText, setGrowAreaText] = useState([]);
    const [seasonData, setSeasonText] = useState([]);
    const [generalData, setGeneralData] = useState([]);
    const [speciesName, setSpeciesName] = useState(false);
    const [file, setFile] = useState(''); 
    const [fileSizeError, setFileSizeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);
    const [cropDisplayName, setCropDisplayName] = useState({});
    const [crop_names, setCropNames] = useState([]);
    const [cropError, setCropError] = useState(false);
    const [cropCodeError, setCropCodeError] = useState(false);
    const [cropLevel, setCropLevel] = useState('');
    const [latLng, setLatLng] = useState({});
    const {latitude, longitude} = GetLatitudeLongitude(props.organization_list, props.organization);
    let previousSeason = '';

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        let {latitude, longitude} = GetLatitudeLongitude(props.organization_list, props.organisation);
        setLatLng({
            latitude: latitude,
            longitude: longitude
        });
        props.actions.setLatitudeLongitudeRequest(
            {
                latitude: props.latitude !== "" ? props.latitude : latitude, 
                longitude: props.longitude !== "" ? props.longitude : longitude, 
            }
        );
    },[]);

    useEffect(()=>{
        setCropText(cropText);
        setGrowAreaText(growAreaText);
        setSeasonText(seasonData);
        setGeneralData(generalData);
        setCropNames(crop_names);
        setCropError(cropError);
        setCropLevel(cropLevel);
    },[cropText, growAreaText, seasonData, generalData, crop_names, cropError, setCropLevel]);

    const getSeasonData = (season) => {
        // var selected_season = props.season_list.filter((temp_data)=>{
        //     return temp_data.key === season;
        // });

        if(props.selected_crop_detail[0].crop_season.length > 0){
        var selected_season = props.selected_crop_detail[0].crop_season.filter((temp_data)=>{
            return temp_data.season_key === season;
        });
        return selected_season;
        }
    }

    const getSuitabilityFromGrowArea = (growArea_id) => {
        var selected_grow_area = data.crop_grow_area.filter((temp_data)=>{
            return temp_data.growArea_id === growArea_id;
        })
        return selected_grow_area;
    }

    const getSuitabilityForSpeificCropSeason = (season_id) => {
        var selected_season_suitability =  data.crop_season.filter((temp_data)=>{
            return temp_data.season_key === season_id
        });
        return selected_season_suitability;
    }

    const textChange = (name, text) => {
        var cropText1= ["displayName", "locale", "typeName", "varietyName", "groupName"];
        var growArea=["growArea"];
        var season=["season","sorting"];
        
        if(cropText1.includes(name)){ 
            let data1 = {[name]: text};

            if(name === "displayName"){
                setCropError(false);
            }

            let data2 = getLanguage() in cropText ? cropText[getLanguage()]: [];
            let typeName = "typeName" in cropText === true ? {"typeName": cropText[getLanguage()].typeName} : {"typeName": ""};
            let varietyName = "varietyName" in cropText === true ? {"varietyName": cropText[getLanguage()].varietyName} : {"varietyName": ""};
            let groupName = "groupName" in cropText === true ? {"groupName": cropText[getLanguage()].groupName} : {"groupName": ""};
            let displayName = "displayName" in cropText === true ? {"displayName": cropText[getLanguage()].displayName} : {"displayName": ""};

            let language = {"locale": languageContent};
           
            let data3 = {...typeName, ...varietyName, ...groupName, ...displayName,  ...data2, ...data1, ...language};
            
            let language2 = getLanguage() === -1 ? Object.keys(cropText).length : getLanguage();

            let data4 = {[language2]: data3};
            let data = {...cropText, ...data4};
    
            setCropText(data);
        }
        else if(growArea.includes(name)){
            if(growAreaText.length > 0) {
               let data = growAreaText.filter((data1)=>{
                   return data1.growAreaId === text.growAreaId;
               })
               if(data.length > 0){
                    let data1 = growAreaText;
                    let data2 = {...data[0], ...text};
                    let index = growAreaText.findIndex((data1)=>{
                        return data1.growAreaId === text.growAreaId;
                    });
                    data1[index] = data2;
                    setGrowAreaText(data1);
               }
               else{
                    let data = growAreaText;
                    data.push(text);
                    setGrowAreaText(data);
               }
            }
            else{
                let data = growAreaText;
                let data1 = {...data, ...text};
                let data2 = Object.assign(data1, data);
                setGrowAreaText([data2]);
            }
        }
        else if(season.includes(name)){
            if(seasonData.length > 0){
                let data = seasonData.filter((data1)=>{
                    return data1.season_key === text.season_key;
                })
                
                if(data.length > 0){
                    let data1 = seasonData;
                    let data2 = { ...data[0], ...text};
                    let index = seasonData.findIndex((data1)=>{
                        return data1.season_key === text.season_key;
                    })
                    data1[index] = data2;
                    setSeasonText(data1);
                }
                else{
                    let data0 = {"crop_id": props.selected_crop_id};
                    let data2 = "season_defaultPlantDate" in text === false && {"season_defaultPlantDate": 0};
                    let data3 = "sorting" in text === false && {"sorting": 0};
                    let data4 = "season_suitability" in text === false && {"season_suitability": 0};
                    let data1 = {...data2, ...data3, ...data4, ...data0, ...text};
                    let data = seasonData;
                    data.push(data1);
                    setSeasonText(data);
                }
            }
            else{
                let data = seasonData;
                let data1 = { ...data, ...text};
                let data2 = Object.assign(data1, data);
                setSeasonText([data2]);
            }
        }
        else{
            if(name === "crop_species_name"){
                setSpeciesName(false);
            }
            if(name == "crop_level"){
                setCropLevel(text);
            }
            let data1 = generalData;
            let data2= {[name]: text};
            let data = {...data1, ...data2};
            
            if(data.code === ""){
                setCropCodeError(true);
            }
            else{
                setCropCodeError(false);
            }
            setGeneralData(data);
        }
    }

    const onSaveButtonClick = () => {
        window.scrollTo(0,0);
        let crop_id = props.selected_crop_id !== "" ? {"crop_id": props.selected_crop_id} : {"crop_id": ""};
        let data1 = generalData;

        data1.crop_species_name = "crop_species_name" in data1 ? data1.crop_species_name : "";
        data1.crop_level = "crop_level" in data1 ? data1.crop_level : "species";
        data1.crop_type = "crop_type" in data1 ? data1.crop_type : "";
        data1.crop_group = "crop_group" in data1 ? data1.crop_group : "";
        data1.variety_iso_code = "variety_iso_code" in data1 ? data1.variety_iso_code : "";
        data1.crop_url = "crop_url" in data1 ? data1.crop_url : "";

        let data2 = {"cropText": cropText};
        
        let data3 = {"growArea": growAreaText};
        let data4 = {"season": seasonData};
        let data5 = {"organisation": props.organisation};
        let data6 = {"image": props.selected_crop_id !== "" ? file == "" ? data.image : file : file};
        let data7 = {...crop_id, ...data5, ...data1, ...data2, ...data3, ...data4, ...data6};

        var has_crop_data_empty = false;
        Object.entries(data7.cropText).map((tempData)=>{
            if(tempData[1].displayName === "" || tempData[1].displayName === undefined || tempData[1].displayName === null){
                if(language_locale.includes(tempData[1].locale) === true){
                    has_crop_data_empty = true;
                }
            }
        })

        if(speciesName === false && data7.crop_species_name !== ""){
            if(fileTypeError === false){
                setFileTypeError(false);
                setSpeciesName(false);
                if(fileSizeError === false){
                    setFileSizeError(false); 

                    var temp_data1 = [];
                    if(data7.cropText.length === 0){
                        setCropError(true);
                        props.actions.languageUpdate(props.language_list[0].locale.slice(0,2));
                    }
                    else{
                        let data8 = Object.assign({}, data7);
                        Object.entries(data8.cropText).map((tempData)=>{
                            let temp_crop_name = tempData.locale;
                            temp_data1.push(temp_crop_name);
                        });
                        setCropError(temp_data1);
                    }

                    if(temp_data1.length >= props.language_list.length && (has_crop_data_empty === false)){
                        if(cropCodeError === false){
                            setCropCodeError(false);
                            if(data7.growArea.length == 0){
                                let grow_area_data = [
                                    // {
                                    //     "growAreaId": 0,
                                    //     "suitability": "0"
                                    // },
                                    {
                                        "growAreaId": 1,
                                        "suitability": "0"
                                    },
                                    {
                                        "growAreaId": 2,
                                        "suitability": "0"
                                    },
                                    {
                                        "growAreaId": 3,
                                        "suitability": "0"
                                    },
                                    {
                                        "growAreaId": 4,
                                        "suitability": "0"
                                    }
                                ]
                                data7.growArea = grow_area_data;
                            }
                            else{
                                let all_grow_area = [];

                                if(props.grow_area_list.length > 0){
                                    props.grow_area_list.map((tmp)=>{
                                        all_grow_area.push(tmp.id);
                                    })
                                }

                                let grow_area_id = [];
                                var added_grow_area = [];

                                data7.growArea.map((tempData)=>{
                                    grow_area_id.push(tempData.growAreaId);
                                })
                                var filtered_grow_area = all_grow_area.filter(x => !grow_area_id.includes(x));

                                if(filtered_grow_area.length > 0){
                                    filtered_grow_area.map((tempData)=>{
                                        added_grow_area.push({
                                            "growAreaId": tempData,
                                            "suitability": "0"
                                        })
                                    })
                                }
                                if(added_grow_area.length > 0){
                                    added_grow_area.map((tempData)=>{
                                        if(tempData.growAreaId !== undefined && tempData.growAreaId !== "" && tempData.growAreaId !== null){
                                            data7.growArea.push(tempData);
                                        }
                                    })
                                }
                            }
                            props.actions.cropRegisterRequest(data7);
                        }
                        else{
                            setCropCodeError(true);
                        }
                    }
                    else if(temp_data1.length !== 0){
                        let index = Object.entries(data7.cropText).findIndex((tempData)=>{
                            if(language_locale.includes(tempData[1].locale) === true){
                                return tempData[1].displayName === "" || tempData[1].displayName === undefined || tempData[1].displayName === null
                            }
                        });

                        setCropError(true);
                        if(index !== -1){
                            var temp_index = language_locale.findIndex((tempData)=>{return tempData === data7.cropText[index].locale});
                            props.actions.languageUpdate(props.language_list[temp_index].locale.slice(0,2));
                        }
                        else{
                            var temp_data1 = [];
                            if(Object.entries(data7.cropText).length === 0){
                                setCropError(true);
                                props.actions.languageUpdate(props.language_list[temp_index].locale.slice(0,2));
                            }
                            else{
                                Object.entries(data7.cropText).map((tempData)=>{
                                    let temp_activity_name = tempData[1].locale;
                                    temp_data1.push(temp_activity_name);
                                });
                            }
                            
                            let temp_index = language_locale.findIndex((tempData)=>{
                                return temp_data1.includes(tempData) === false; 
                            })
                            props.actions.languageUpdate(props.language_list[temp_index].locale.slice(0,2));
                        }
                    }
                }
                else{
                    setFileSizeError(true); 
                }
            }
            else{
                setFileTypeError(true);
            }
        }
        else{
            setSpeciesName(true);

        }
    }

    const getCropSeasonSuitability = (season_key) => {
        if(Object.values(data).length > 0){
            let temp_data = data.crop_season.filter((tmp)=>{return tmp.season_key === season_key});

            if(temp_data.length > 0){
                return temp_data[0].suitability;
            }
            else{
                return "0";
            }
        }
        else{
            return "0";
        }
    }

    const getGrowAreaSuitability = (grow_area_id) => {
        let tmp_data = data.crop_grow_area.filter((tmp)=>{return tmp.growArea_id === grow_area_id});

        if(tmp_data.length > 0){
            return tmp_data[0].suitability;
        }
        else{
            return "0";
        }
    }

    useEffect(()=>{
        var crop_text= ["displayName", "locale", "typeName", "varietyName", "groupName"];
        var general = ["speciesName","level","type","group","varietyisocode","url"]

        if(props.selected_crop_id !== ""){
            if(Object.values(data).length > 0){
                if("crop_text" in data){
                    let crop_text_data = [];
                    let grow_area_data = [];
                    let season_data = [];
                    let general_data = [];
                    for (const [key, value] of Object.entries(data)) {
                        if(general.includes(key)){
                            key === "speciesName" && (general_data["crop_species_name"] = value); 
                            key === "level" && (general_data["crop_level"] = value); 
                            key === "type" && (general_data["crop_type"] = value); 
                            key === "varietyisocode" && (general_data["variety_iso_code"] = value); 
                            key === "url" && (general_data["crop_url"] = value); 
                            general_data["code"] = data.code;
                        }
                        setGeneralData(general_data);
                    }

                    data.crop_text.map((crop_data_text)=>{
                        let data2 = {};
                        for (const [key, value] of Object.entries(crop_data_text)) {
                            if(crop_text.includes(key)){
                                data2[key] = value;
                            }
                        }
                        crop_text_data.push(data2);
                        return ()=>{}
                    });
                    setCropText(crop_text_data);

                    data.crop_season.map((crop_data_text)=>{
                        let data2 = {};
                        for (const [key, value] of Object.entries(crop_data_text)) {
                            if(key === "suitability"){
                                data2["season_suitability"] = value;
                            }
                            else if(key === "defaultPlantDate"){
                                data2["season_defaultPlantDate"] = value;
                            }
                            else{
                                data2[key] = value;
                            }
                        }
                        season_data.push(data2);
                        return ()=>{};
                    })
                    setSeasonText(season_data);

                    data.crop_grow_area.map((crop_data_text)=>{
                        let data2 = {};
                        for (const [key, value] of Object.entries(crop_data_text)) {
                            if(key === 'growArea_id'){
                                data2["growAreaId"] =  value;
                            }
                            else{
                                data2[key] = value;
                            }
                        }
                        grow_area_data.push(data2);
                    })
                    setGrowAreaText(grow_area_data);
                }
            }
        }
    },[]);

    const getLanguage = () => {
        let data = Object.values(cropText).findIndex((data)=>{
            return data.locale === languageContent;
        })
        return data;
    }

    const getLanguage1 = () => {
        let data = Object.values(cropText).findIndex((data)=>{
            return data.locale === 'en_EN';
        })
        return data;
    }

    useEffect(()=>{
        setData(data);
    },[data]);

    useEffect(()=>{
        //setData(props.selected_crop_detail[0]);
    },[props.crop_register_loading,props.selected_crop_detail_loading, props.loading])

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const getLanguageIndex = (data) => {
        let data1 = Object.values(data).findIndex((tempData)=>{
            return tempData.locale === languageContent;
        });
        return data1;
    }

    const handleChange = (event) => {
        if(image_type.includes(event.target.files[0].type) === true){
            setFileTypeError(false);
            if(event.target.files[0].size <= 100000){
                setFileSizeError(false);
                toDataURL(URL.createObjectURL(event.target.files[0]),
                    function (dataUrl) {
                        let temp_data_url = dataUrl.split(",");
                        setFile(temp_data_url[1]);
                    }
                )
            }
            else{
                setFileSizeError(true); 
                setFile("");
            }
        }
        else{
            setFileTypeError(true);
            setFile("");
        }
    }

    const toDataURL = (src, callback, outputFormat) => {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          let dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
        };
        image.src = src;
        if (image.complete || image.complete === undefined) {
          image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
          image.src = src;
        }
    }

    useEffect(()=>{
        setFile(file)
    },[file]);

    useEffect(()=>{
        setFileSizeError(fileSizeError);
        setFileTypeError(fileTypeError);
        setCropDisplayName(cropDisplayName);
    },[fileSizeError, fileTypeError, cropDisplayName]);

    useEffect(()=>{
        setCropCodeError(cropCodeError);
    },[cropCodeError]);

    const getGrowAreaName = (growareas) => {
        let data = growareas.filter((tmp)=>{return tmp.locale === "en_EN"});
        if(data.length > 0){
            return data[0].name
        }
    }

    const getLocaleIndex = (data) => {
        let data_index = Object.values(data).findIndex((tmp)=>{return tmp.locale.includes(props.lang)});

        return data_index;
    }

    const changeLatitudeLongitude = (name, value) => {
       setLatLng(lat_lng => ({...lat_lng, [name]: value}));
    }

    const submitLatitudeLongitude = () =>{
        let params = {
            ...{
                organisation: props.organization,
                crop_id: props.selected_crop_id,
            },
            ...{
                latitude: "latitude" in latLng ? latLng.latitude : 0,
                longitude: "longitude" in latLng ? latLng.longitude : 0
            }
        };

        props.actions.seasonListRequest(params);
        props.actions.setLatitudeLongitudeRequest({
            latitude: "latitude" in latLng ? latLng.latitude : 0,
            longitude: "longitude" in latLng ? latLng.longitude : 0
        });
    }

    const renderData = () => {
        return (
            <> 
                <div className="formCard">
                    <div style={{display: 'flex', flexDirection: 'column',flex: 1, flexWrap: 'wrap'}}>
                        <div className="formWrapper">
                            <div className="leftFormCard">
                                {
                                    props.selected_crop_id !== "" &&
                                        <TextBox
                                            fertilizer={true}
                                            label="Crop Unique ID"
                                            name="crop_id"
                                            onTextChange={textChange}
                                            value={ 
                                                props.selected_crop_id !== "" ? 
                                                    data.id
                                                :
                                                    undefined
                                            }
                                            disabled={true}
                                        />
                                }

                                {
                                    props.selected_crop_id !== "" &&
                                        <TextBox
                                            fertilizer={true}
                                            label="Crop Code"
                                            name="code"
                                            onTextChange={textChange}
                                            value={ 
                                                props.selected_crop_id !== "" ? 
                                                    data.code
                                                :
                                                    undefined
                                            }
                                            error={cropCodeError === true ? "This field is required" : undefined}
                                        />
                                }
                                
                                <TextBox 
                                    fertilizer={true}
                                    label="Species" 
                                    onTextChange={textChange}
                                    name={"crop_species_name"}
                                    value={
                                        props.selected_crop_id !== "" ? 
                                            data.speciesName
                                        :
                                            undefined
                                    }
                                    error={speciesName === true ? "This field is required" : undefined}
                                />

                                <TextBox 
                                    fertilizer={true}
                                    label="Group" 
                                    onTextChange={textChange}
                                    name={"crop_group"}
                                    value={
                                        props.selected_crop_id !== "" ? 
                                            data.group
                                        :
                                            undefined
                                    }
                                />

                                <TextBox 
                                    fertilizer={true}
                                    label="Type" 
                                    onTextChange={textChange}
                                    name={"crop_type"}
                                    value={
                                        props.selected_crop_id !== "" ? 
                                            data.type
                                        :
                                            undefined
                                    }
                                />

                                <TextBox 
                                    fertilizer={true}
                                    label="Variety ISO Code"
                                    onTextChange={textChange}
                                    name={"variety_iso_code"}
                                    value={
                                        props.selected_crop_id !== "" ? 
                                            data.varietyisocode
                                        :
                                            undefined
                                    }
                                />

                                <div className="TextBoxContainer">
                                    <div className="leftSelectLabelContainer">
                                        <span>Level</span>
                                    </div>

                                    <div className="rightSelectLabelContainer">
                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                            <select 
                                                className="selectInput"
                                                value={
                                                    props.selected_crop_id !== "" ? 
                                                        cropLevel === "" ? data.level : cropLevel
                                                    :
                                                        undefined
                                                }
                                                name="level"
                                                onChange={(e)=>{
                                                    textChange("crop_level",e.target.value);
                                                    setCropLevel(e.target.value);
                                                }}

                                            >
                                                {
                                                    levels.map((level,key)=>{
                                                        return (
                                                            <option 
                                                                key={key}
                                                                value={level.value}
                                                            >
                                                                {level.label}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="rightFormCard">
                                <TextBox 
                                    fertilizer={true}
                                    label="Crop URL" 
                                    name={"crop_url"}
                                    onTextChange={textChange}
                                    value={
                                        props.selected_crop_id !== "" ? 
                                            data.url
                                        :
                                            undefined
                                    }
                                />

                                <FileType 
                                    label={"Crop Image"}
                                    handleChange={handleChange}
                                    error={fileSizeError === true ? "Maximum limit size exceeds 100 KB" : undefined}
                                    error1={fileTypeError === true ? ".jpeg and .png file type are only allowed." : undefined}
                                    file={
                                        props.selected_crop_id !== "" ?
                                            file == "" ?
                                                data.image !== null &&
                                                    data.image
                                            :
                                                file
                                        :
                                            file
                                    }
                                />

                                {
                                    languageLength.map((lang,key)=>{
                                        return (
                                            langContent === lang && 
                                                <div key={key}>
                                                    {
                                                        "crop_text" in data ?
                                                            <div>
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Crop Name"
                                                                    onTextChange={textChange}
                                                                    name={"displayName"}
                                                                    value={
                                                                        getLanguage() in cropText ?
                                                                            cropText[getLanguage()].displayName
                                                                        :
                                                                            props.selected_crop_id !== "" ? 
                                                                                data.crop_text.length > 0 ?
                                                                                    "displayName" in data.crop_text ?
                                                                                        data.crop_text[key].displayName
                                                                                    :
                                                                                        null
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ?
                                                                                cropText[getLanguage1()].displayName
                                                                            :
                                                                                props.selected_crop_id !== "" ? 
                                                                                    data.crop_text.length > 0 ?
                                                                                        "displayName" in data.crop_text ?
                                                                                            data.crop_text[key].displayName
                                                                                        :
                                                                                            undefined
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                    error={cropError === true ? "Crop Name is required." : undefined} 
                                                                />

                                                                <TextBox
                                                                    fertilizer={true}
                                                                    onTextChange={textChange}
                                                                    name={"groupName"}
                                                                    label="Group Name"
                                                                    value={
                                                                        getLanguage() in cropText ?
                                                                            cropText[getLanguage()].groupName
                                                                        :
                                                                            props.selected_crop_id !== "" ? 
                                                                                data.crop_text.length > 0 ?
                                                                                    "groupName" in data.crop_text ?
                                                                                        data.crop_text[key].groupName
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ?
                                                                                cropText[getLanguage1()].groupName
                                                                            :
                                                                                props.selected_crop_id !== "" ? 
                                                                                    data.crop_text.length > 0 ?
                                                                                        "groupName" in data.crop_text ?
                                                                                            data.crop_text[key].groupName
                                                                                        :
                                                                                            undefined
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Type Name"
                                                                    onTextChange={textChange}
                                                                    name={"typeName"}
                                                                    value={
                                                                        getLanguage() in cropText ?
                                                                            cropText[getLanguage()].typeName
                                                                        :
                                                                            props.selected_crop_id !== "" ? 
                                                                                data.crop_text.length > 0 ?
                                                                                    "typeName" in data.crop_text ?
                                                                                        data.crop_text[key].typeName
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ?
                                                                                cropText[getLanguage1()].typeName
                                                                            :
                                                                                props.selected_crop_id !== "" ?
                                                                                    data.crop_text.length > 0 ? 
                                                                                        "typeName" in data.crop_text ?
                                                                                            data.crop_text[key].typeName
                                                                                        :
                                                                                            undefined
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Variety Name"
                                                                    onTextChange={textChange}
                                                                    name={"varietyName"}
                                                                    value={
                                                                        getLanguage() in cropText ?
                                                                            cropText[getLanguage()].varietyName
                                                                        :
                                                                            props.selected_crop_id !== "" ? 
                                                                                data.crop_text.length > 0 ?
                                                                                    "varietyName" in  data.crop_text ?
                                                                                        data.crop_text[key].varietyName
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                            :
                                                                                undefined
                                                                    }
                                                                    value1={
                                                                        props.lang !== "en" ?
                                                                            getLanguage1() in cropText ?
                                                                                cropText[getLanguage1()].varietyName
                                                                            :
                                                                                props.selected_crop_id !== "" ? 
                                                                                    data.crop_text.length > 0 ?
                                                                                        "varietyName" in data.crop_text ?
                                                                                            data.crop_text[key].varietyName
                                                                                        :
                                                                                            undefined
                                                                                    :
                                                                                        undefined
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                            </div>
                                                        :
                                                            <div>
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Crop Name"
                                                                    onTextChange={textChange}
                                                                    name={"displayName"}  
                                                                    value={getLanguage() in cropText ? cropText[getLanguage()].displayName : undefined}
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ? cropText[getLanguage1()].displayName : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                    error={cropError === true ? "Crop Name is required." : undefined} 
                                                                />
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Group Name"
                                                                    onTextChange={textChange}
                                                                    name={"groupName"}
                                                                    value={getLanguage() in cropText ? cropText[getLanguage()].groupName : undefined}
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ? cropText[getLanguage1()].groupName : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Type Name"
                                                                    onTextChange={textChange}
                                                                    name={"typeName"}
                                                                    value={getLanguage() in cropText ? cropText[getLanguage()].typeName : undefined}
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ? cropText[getLanguage1()].typeName : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                                <TextBox
                                                                    fertilizer={true}
                                                                    label="Variety Name"
                                                                    onTextChange={textChange}
                                                                    name={"varietyName"}
                                                                    value={getLanguage() in cropText ? cropText[getLanguage()].varietyName : undefined}
                                                                    value1={
                                                                        languageContent !== "en_EN" ?
                                                                            getLanguage1() in cropText ? cropText[getLanguage1()].varietyName : undefined
                                                                        :
                                                                            undefined
                                                                    }
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="formWrapper">
                            <div className="formContainer">
                                <div className="formTitle">
                                    <span>Grow Area</span>
                                </div>

                                <div className="formContainerBody">
                                    {
                                        props.grow_area_list.length > 0 &&
                                            props.grow_area_list.map((temp_grow_area,key)=>{
                                                return (
                                                    <SelectBox 
                                                        key={key}
                                                        label={
                                                            temp_grow_area.grow_area_text.length > 0  &&
                                                                getLocaleIndex(temp_grow_area.grow_area_text) !== -1 ?
                                                                    temp_grow_area.grow_area_text[getLocaleIndex(temp_grow_area.grow_area_text)].name
                                                                :
                                                                    ""
                                                        }
                                                        weeknumber={false} 
                                                        name="growArea"
                                                        growAreaId={
                                                            temp_grow_area.grow_area_text.length > 0  &&
                                                                getLanguageIndex(temp_grow_area.grow_area_text) !== -1 ?
                                                                    temp_grow_area.grow_area_text[getLanguageIndex(temp_grow_area.grow_area_text)].growArea_id
                                                                :
                                                                    temp_grow_area.grow_area_text[0].growArea_id
                                                        }
                                                        onTextChange={textChange}
                                                        checked={
                                                            props.selected_crop_id !== "" ? 
                                                                getSuitabilityFromGrowArea(temp_grow_area.id).length > 0 &&  
                                                                    getSuitabilityFromGrowArea(temp_grow_area.id).length == 1 ?
                                                                        getSuitabilityFromGrowArea(temp_grow_area.id)[0].suitability === "0" ?
                                                                            false
                                                                        :
                                                                            true
                                                                    :
                                                                        false
                                                            :
                                                                false
                                                        }
                                                        disabled={false}
                                                    />
                                                )
                                            })
                                    }
                                </div>
                            </div>

                            <div className="formContainer">
                                <div className="formTitle">
                                    <div style={{flex: 4, alignItems: 'center', justifyContent: 'center'}}>
                                        <span>Season</span>
                                    </div>
                                    <div style={{flex: 6, alignItems: 'center', justifyContent: 'center'}}>
                                        <form style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <TextBox 
                                                id="latitude"
                                                name="latitude"
                                                onTextChange={changeLatitudeLongitude}
                                                placeholder={"Latitude"}
                                                type="latLng"
                                                value={props.latitude}
                                            />
                                            <TextBox 
                                                id="longitude"
                                                name="longitude"
                                                onTextChange={changeLatitudeLongitude}
                                                placeholder={"Longitude"}
                                                type="latLng"
                                                value={props.longitude}
                                            />

                                            <CustomButton 
                                                name="Change Location"  
                                                image=""
                                                styles={{width: '50%'}}
                                                onSaveButtonClick={submitLatitudeLongitude}
                                            />      
                                        </form>
                                    </div>
                                </div>

                                <div className="formContainerBody">
                                    {
                                        props.season_list_loading === true ?
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <Spinner style={{position: 'relative'}} />
                                            </div>
                                        :
                                            props.season_list.length > 0 &&
                                                props.season_list.map((temp_season,key)=>{
                                                    return (
                                                        <SelectBox
                                                            key={key}
                                                            label={temp_season.key}
                                                            weeknumber={true} 
                                                            name="season"
                                                            defaultPlantDate={
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_season.length > 0 &&
                                                                        getSeasonData(temp_season.key).length > 0 ?
                                                                            getSeasonData(temp_season.key)[0].defaultPlantDate
                                                                        :
                                                                            undefined
                                                                :
                                                                    ""
                                                            }
                                                            season_suitability={temp_season.suitability}
                                                            sorting={
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_season.length > 0 &&
                                                                        getSeasonData(temp_season.key).length > 0 ?
                                                                            getSeasonData(temp_season.key)[0].sorting
                                                                        :
                                                                            undefined
                                                                :
                                                                    ""
                                                            }
                                                            onTextChange={textChange}
                                                            week={
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_season.length > 0 &&
                                                                        getSeasonData(temp_season.key).length > 0 ?
                                                                            getSeasonData(temp_season.key)[0].defaultPlantDate
                                                                        :
                                                                            undefined
                                                                :
                                                                    ""
                                                            }
                                                            checked={
                                                                props.selected_crop_id !== "" ? 
                                                                    data.crop_season.length > 0 &&
                                                                        getSuitabilityForSpeificCropSeason(temp_season.key).length > 0 ?
                                                                            getSuitabilityForSpeificCropSeason(temp_season.key)[0].suitability === '0' ?
                                                                                false
                                                                            :
                                                                                true
                                                                        :
                                                                            undefined
                                                                :
                                                                    undefined
                                                            }
                                                            disabled={true}
                                                        />
                                                    )
                                                })
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <div style={{display: 'flex', flexDirection:'row'}}>
                            {
                                renderMatrix()
                            }
                        </div> */}
                    </div>
                </div>
            </>
        )
    }

    const textChange1 = (name, text, season_key, grow_area_id) => {
        let temp_data = {...data};

        let tmp_season_data = temp_data.crop_season.filter((tmp)=>{return tmp.season_key === season_key});

        if(tmp_season_data.length > 0){
            if(tmp_season_data[0].suitability === "0"){
                tmp_season_data[0].suitability = "1";
            }
            else{
                tmp_season_data[0].suitability = "0";
            }
        }

        let tmp_growarea_data = temp_data.crop_grow_area.filter((tmp)=>{return tmp.growArea_id === grow_area_id});

        if(tmp_growarea_data.length > 0){
            // if(tmp_growarea_data[0].suitability === "0"){
            //     tmp_growarea_data[0].suitability = "1";
            // }
            // else{
            //     tmp_growarea_data[0].suitability = "0";
            // }
        }
        else{
            let tmp_grow_area_data = {
                "crop_id": props.selected_crop_id,
                "cropgrowarea_id": "",
                "growArea_id": grow_area_id,
                "org_id": props.uid,
                "suitability": 1
            }

            temp_data.crop_grow_area.push(tmp_grow_area_data);
        }

        setData(temp_data);
    }

    const renderMatrix = () => {
        return (
            <>
                <div style={{display: 'flex', flexDirection: 'column',flex: 1}}>
                    <table style={{width: "100%"}} className="tableContainer" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Season Name</th>
                                {
                                    props.season_list.map((season,key)=>{
                                        return (
                                            <th key={key}>{season.key}</th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <th>Season Band</th>
                                {
                                    props.season_list.map((season,key)=>{
                                        return (
                                            <th key={key}>{season.Band}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                props.grow_area_list.map((grow_area, grow_area_key)=>{
                                    let grow_area_suitability = getGrowAreaSuitability(grow_area.id);

                                    return (
                                        <>
                                            {
                                                grow_area_key === 0 && 
                                                    <tr key={grow_area_key}>
                                                        <td>Grow Area</td>
                                                        {
                                                            props.season_list.map((season, season_key)=>{
                                                                return (
                                                                    <td key={season_key}>
                                                                        <label>
                                                                            <input
                                                                                type="text" 
                                                                                className="textBoxInput"
                                                                                style={{width: '80%'}}
                                                                                placeholder={"Default Plant Date"}
                                                                                onChange={(e)=>{textChange1("growarea", e.target.value, season.key, grow_area.id)}}
                                                                                value={
                                                                                    props.selected_crop_id !== "" ? 
                                                                                        data.crop_season.length > 0 &&
                                                                                            getSeasonData(season.key).length > 0 ?
                                                                                                getSeasonData(season.key)[0].defaultPlantDate
                                                                                            :
                                                                                                undefined
                                                                                    :
                                                                                        ""
                                                                                }
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                            }
                                            <tr>
                                                <td key={grow_area_key}>{getGrowAreaName(grow_area.grow_area_text)}</td>
                                                {
                                                    props.season_list.map((season,season_key)=>{
                                                        var season_suitbaility = getCropSeasonSuitability(season.key);

                                                        if(season_key !== 0)
                                                            previousSeason = props.season_list[season_key - 1].key;

                                                        return (
                                                            //previousSeason.includes(season.key.charAt(0)) === false &&
                                                                <td colSpan="" key={season_key}>
                                                                    {
                                                                        season.key.includes('w') === false ?
                                                                            <label className="container" style={{display: 'inline'}}>
                                                                                <input
                                                                                    type="radio" 
                                                                                    id={grow_area.id+season.key+season_key}
                                                                                    className="selectInput"
                                                                                    name={grow_area.id+season.key+season_key} 
                                                                                    onChange={(e)=>{textChange1("season", e.target.value, season.key, grow_area.id)}}
                                                                                    value={season_suitbaility}
                                                                                    defaultChecked={
                                                                                        (getGrowAreaName(grow_area.grow_area_text).includes('+') === false) ?
                                                                                            grow_area_suitability === "1" ?
                                                                                                season_suitbaility === "0" ? false : true
                                                                                            :
                                                                                                false
                                                                                        :   
                                                                                            false  
                                                                                    }
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        :
                                                                            <label className="container" style={{display: 'inline'}}>
                                                                                <input
                                                                                    type="radio" 
                                                                                    id={
                                                                                        season_key !== 0 ? 
                                                                                            grow_area.id+props.season_list[season_key - 1].key+(season_key - 1)
                                                                                        :
                                                                                            grow_area.id+season.key+(season_key - 1)
                                                                                    }
                                                                                    className="selectInput"
                                                                                    name={
                                                                                        season_key !== 0 ?
                                                                                            grow_area.id+props.season_list[season_key - 1].key+(season_key - 1)
                                                                                        :
                                                                                            grow_area.id+season.key+(season_key - 1)
                                                                                    } 
                                                                                    value={season_suitbaility}
                                                                                    onChange={(e)=>{textChange1("season", e.target.value, season.key, grow_area.id)}}
                                                                                    defaultChecked={
                                                                                        (getGrowAreaName(grow_area.grow_area_text).includes('+') === true || getGrowAreaName(grow_area.grow_area_text).includes('Swamp') === true) && season.key.includes('w') === true ?
                                                                                            grow_area_suitability === "1" ?
                                                                                                season_suitbaility === "0" ? false : true
                                                                                            :
                                                                                                false
                                                                                        :
                                                                                            false
                                                                                    }
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                    }
                                                                    
                                                                    {/* <span style={{paddingLeft: '8%', paddingRight: '18%'}}>Or</span>

                                                                    {
                                                                        //season.key.includes('w') === true &&
                                                                            <label className="container" style={{display: 'inline'}}>
                                                                                <input
                                                                                    type="radio" 
                                                                                    //id={grow_area.id+season.key+season_key}
                                                                                    className="selectInput"
                                                                                    //name={grow_area.id+season.key+season_key} 
                                                                                    //value={season_suitbaility}
                                                                                    onChange={(e)=>{textChange1("season", e.target.value, season.key, grow_area.id)}}
                                                                                    defaultChecked={
                                                                                        (((getGrowAreaName(grow_area.grow_area_text).includes('+') === true) || (getGrowAreaName(grow_area.grow_area_text).includes('Swamp') === true)) && season.key.includes('w') === true) === true ?
                                                                                            grow_area_suitability === "1" ?
                                                                                                season_suitbaility === "0" ? false : true
                                                                                            :
                                                                                                false
                                                                                        :
                                                                                            false
                                                                                    }
                                                                                    // disabled={
                                                                                    //     getGrowAreaName(grow_area.grow_area_text).includes('+') === true ? false : true
                                                                                    // }
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                    } */}
                                                                </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    return (
        <div>
            <Header header={false} />

            <Breadcrumb 
                save={
                    (props.user_role_type !== "Viewer" && props.organisation === to_org)  ? 
                        true 
                    : 
                        (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  ? 
                            true
                        :
                            false
                } 
                onSaveButtonClick={onSaveButtonClick} 
            />

            <div className="generalBody">
                <ToastContainer/>
                {
                    props.selected_crop_id === "" ?
                        renderData()
                    :
                        (props.crop_register_loading === true || props.selected_crop_detail_loading === true || props.loading === true) ?
                            <div><Spinner /></div>
                        :
                            renderData()
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selected_crop_detail: state.crop.selected_crop_detail,
        selected_crop_id: state.crop.selected_crop_id,
        selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
        lang: state.lang.language,
        season_list: state.season.season_list,
        grow_area_list: state.growArea.grow_area_list,
        organisation: state.auth.organization,
        crop_register_loading: state.crop.crop_register_loading,
        loading: state.crop.loading,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        uid: state.auth.uid,
        organization_list: state.organization.organization_list,
        organization: state.auth.organization,
        season_list_loading: state.season.season_list_loading,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
        latitude: state.crop.latitude,
        longitude: state.crop.longitude,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                cropRegisterRequest,
                languageUpdate,
                seasonListRequest,
                setLatitudeLongitudeRequest,
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(CropGeneral);
