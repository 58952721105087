import React, { useEffect, useState } from 'react';
import Header from '../../../../common/header';
import Breadcrumb from '../../../../common/breadcrumb';
import Spinner from '../../../../common/spinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './activity.css';
import ActivityMedia from './activityMedia';
import './activity.css';
import '../../../index.scss';
import {
    removeSpecificActivity,
    deleteActivityMedia,
    clearSelectedActivityMedia,
    allMediaListRequest,
    specificCropActivityListRequest1,
    activityList,
    allGenericListRequest,
    selectedGenericActivity
} from './action';
import { GetToOrganisation } from '../../../lib/getToOrganisation';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Activity = (props) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState('');
    const [selectedActivityMedia, setSelectedActivityMedia] = useState([]);
    const [selectedActivityMediaId, setSelectedActivityMediaId] = useState('');
    const [clickedEditButton, setClickedEditButton] = useState(0);
    const [allMediaList, setAllMediaList] = useState([]);
    const [activityCrop, setActivityCrop] = useState([]);

    const getLangContent = (language) => {
        let data = props.language_list.findIndex((tempData) => {
            return tempData.locale.includes(language) === true
        })
        if (data !== -1) {
            return data;
        }
        else {
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data = props.language_list.filter((tempData) => {
            return tempData.locale.includes(language) === true
        })
        if (data.length > 0) {
            return data[0].locale;
        }
        else {
            return "en_EN";
        }
    }

    useEffect(() => {
        setSelectedActivityMedia(selectedActivityMedia);
    }, [selectedActivityMedia]);

    useEffect(() => {
        setSelectedActivityId(selectedActivityId);
    }, [selectedActivityId]);

    useEffect(() => {
        props.actions.removeSpecificActivity();
        props.actions.clearSelectedActivityMedia();
    }, []);

    useEffect(() => {
        setSelectedActivityMediaId(selectedActivityMediaId);
    }, [selectedActivityMediaId]);

    useEffect(() => {
        setSelectedActivityMediaId("")
    }, [props.activity_media_list])

    const activityId = (activity_id) => {
        if (activity_id === "all") {
            // props.actions.allMediaListRequest(
            //     props.selected_crop_id
            // );
        }
        setSelectedActivityId(activity_id)
    }

    const getLanguage = (activity_id, id) => {
        if (allMediaList.length > 0) {
            let data1 = allMediaList.filter((tempData) => {
                return tempData.id === id;
            });

            if (data1.length > 0) {
                let data = Object.values(data1[0].media).filter((tempData) => {
                    return tempData.id === activity_id;
                });

                if (data.length > 0) {
                    let index = Object.values(data[0].media_text).findIndex((tempData) => {
                        return tempData.locale === languageContent;
                    })
                    return index;
                }
            }
            else {
                return -1;
            }
        }
        else {
            return -1;
        }
    }

    const getLanguage1 = (activity_id, id) => {
        if (allMediaList.length > 0) {
            let data1 = allMediaList.filter((tempData) => {
                return tempData.id === id;
            });

            if (data1.length > 0) {
                let data = Object.values(data1[0].media).filter((tempData) => {
                    return tempData.id === activity_id;
                });
                if (data.length > 0) {
                    let index = Object.values(data[0].media_text).findIndex((tempData) => {
                        return tempData.locale === "en_EN";
                    })
                    return index;
                }
            }
            else {
                return -1;
            }
        }
        else {
            return -1;
        }
    }

    const getLanguageData = (data) => {
        let tempData = Object.values(data).findIndex((temp_data) => {
            return temp_data.locale === languageContent
        })
        return tempData;
    }

    let activity_list = [];

    const countOccurnce = id => {
        return activity_list.reduce((a, v) => (v === id ? a + 1 : a), 0);
    }

    useEffect(() => {
        var allMediaList = [];
        if (props.all_media_list.length > 0) {
            allMediaList = props.all_media_list;
        }
        if (props.selected_crop_id == 0) {
            if (props.selected_generic_activity !== "") {
                if (Object.keys(props.all_media_list).length > 0) {
                    let data = Object.values(props.all_media_list).filter((tempData) => {
                        return tempData.category == props.selected_generic_activity
                    })
                    setAllMediaList(data);
                }
            }
            else if (selectedActivityId === "all" && props.selected_generic_activity === "") {
                setSelectedActivityId("");
                setAllMediaList(allMediaList);
            }
            else {
                setSelectedActivityId("");
                setAllMediaList(allMediaList);
            }
        }
        else {
            setAllMediaList(allMediaList);
        }
    }, [props.selected_generic_activity, props.all_media_list]);

    useEffect(() => {
        setSelectedActivityId("");
    }, [props.selected_generic_activity]);

    const editActivityMediaContent = (data) => {
        if (Object.values(data).length > 0) {
            if (data.media_text.length > 0) {
                let index = data.media_text.findIndex((tmp) => {
                    return tmp.locale.includes(props.lang)
                });

                if (index !== -1) {
                    document.getElementById('title').value = data.media_text[index].title;
                    document.getElementById('description').value = data.media_text[index].description;
                }
            }
        }
    }

    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected activity media ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div
                            className="modalButton"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal();
                                props.actions.deleteActivityMedia({
                                    "media_id": selectedActivityMedia.id,
                                    "crop_id": props.selected_crop_id,
                                    "activity_id": selectedActivityMedia.activity_id
                                })
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div
                            className="modalButton"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <Breadcrumb />

            <ToastContainer />

            <div className="generalBody">
                <div className="formCard" style={{ flexDirection: 'column' }}>

                    {
                        props.activity_register_loading === true || props.specific_crop_activity_list_loading === true ?
                            <Spinner />
                            :
                            <>
                                <ActivityMedia
                                    editSelectedMedia={selectedActivityMedia}
                                    selectedActivityMediaId={selectedActivityMediaId}
                                    activityId={activityId}
                                    selectedActivityMedia={selectedActivityMedia}
                                    editButton={clickedEditButton}
                                    activityMediaList={allMediaList}
                                    activityCrop={activityCrop}
                                />

                                <table style={{ width: "100%" }} className="tableContainer" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Media Title</th>
                                            <th>Media Link</th>
                                            <th>Media Type</th>
                                            {
                                                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                    <>
                                                        <th><span>Edit</span></th>
                                                        <th><span>Delete</span></th>
                                                    </>
                                                :
                                                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                        <>
                                                            <th><span>Edit</span></th>
                                                            <th><span>Delete</span></th>
                                                        </>
                                            }
                                        </tr>
                                    </thead>

                                    {
                                        selectedActivityId !== "" || selectedActivityId === "all" ?
                                            Object.values(props.activity_media_list).length > 0 &&
                                                Object.values(props.activity_media_list).map((activityMedia1) => {
                                                    return (
                                                        activityMedia1.media.map((activityMedia, key) => {
                                                            activity_list.push(activityMedia1.id)
                                                            return (
                                                                <tbody key={key} className={activityMedia.id === selectedActivityMediaId ? "selectedRow" : ""}>
                                                                    {
                                                                        countOccurnce(activityMedia1.id) === 1 &&
                                                                        <tr className="activityName">
                                                                            <td colSpan={6} >
                                                                                {
                                                                                    activityMedia1.image !== null &&
                                                                                    <span>
                                                                                        <img src={`data:image/png;base64, ${atob(activityMedia1.image)}`} width={"30"} height={"30"} style={{ paddingRight: '5px', marginBottom: "-10px" }} />
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                        <span>{activityMedia1.activity_text[getLanguageData(activityMedia1.activity_text)].name}</span>
                                                                                        :
                                                                                        undefined
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    <tr>
                                                                        <td className="">{activityMedia.id}</td>
                                                                        <td className="">{
                                                                            "media_text" in activityMedia ?
                                                                                activityMedia.media_text.length > 0 ?
                                                                                    getLanguage(activityMedia.id, activityMedia1.id) !== -1 && getLanguage(activityMedia.id, activityMedia1.id) !== undefined ?
                                                                                        activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].title
                                                                                        :
                                                                                        undefined
                                                                                    :
                                                                                    undefined
                                                                                :
                                                                                undefined
                                                                        }</td>
                                                                        <td className="">{
                                                                            "media_text" in activityMedia ?
                                                                                activityMedia.media_text.length > 0 ?
                                                                                    getLanguage(activityMedia.id, activityMedia1.id) !== -1 && getLanguage(activityMedia.id, activityMedia1.id) !== undefined ?
                                                                                        activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].link
                                                                                        :
                                                                                        undefined
                                                                                    :
                                                                                    undefined
                                                                                :
                                                                                undefined
                                                                        }</td>
                                                                        <td className="">{
                                                                            "media_text" in activityMedia ?
                                                                                activityMedia.media_text.length > 0 ?
                                                                                    getLanguage(activityMedia.id, activityMedia1.id) !== -1 && getLanguage(activityMedia.id, activityMedia1.id) !== undefined ?
                                                                                        activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].mediaType
                                                                                        :
                                                                                        "NULL"
                                                                                    :
                                                                                    "NULL"
                                                                                :
                                                                                "NULL"
                                                                        }</td>
                                                                        {
                                                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                                <>
                                                                                    <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                        <img
                                                                                            src={require('../../../../images/edit.svg')}
                                                                                            alt="edit"
                                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                document.getElementById('title').value = "";
                                                                                                document.getElementById('description').value = "";
                                                                                                window.scrollTo(0, 0);
                                                                                                editActivityMediaContent(activityMedia);
                                                                                                setSelectedActivityMedia(activityMedia);
                                                                                                setSelectedActivityMediaId(activityMedia.activity_id)
                                                                                                setClickedEditButton(clickedEditButton + 1);
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                        <img
                                                                                            src={require('../../../../images/delete.png')}
                                                                                            alt="delete"
                                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                setSelectedActivityMedia(activityMedia);
                                                                                                openModal();
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </>
                                                                            :
                                                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                                    <>
                                                                                        <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                            <img
                                                                                                src={require('../../../../images/edit.svg')}
                                                                                                alt="edit"
                                                                                                style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    document.getElementById('title').value = "";
                                                                                                    document.getElementById('description').value = "";
                                                                                                    window.scrollTo(0, 0);
                                                                                                    editActivityMediaContent(activityMedia);
                                                                                                    setSelectedActivityMedia(activityMedia);
                                                                                                    setSelectedActivityMediaId(activityMedia.activity_id)
                                                                                                    setClickedEditButton(clickedEditButton + 1);
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                            <img
                                                                                                src={require('../../../../images/delete.png')}
                                                                                                alt="delete"
                                                                                                style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    setSelectedActivityMedia(activityMedia);
                                                                                                    openModal();
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                    </> 
                                                                        }

                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })
                                                    )
                                                })
                                            :
                                                Object.values(allMediaList).map((activityMedia1) => {
                                                    return (
                                                        (activityMedia1.status || activityMedia1.status === undefined) &&
                                                            activityMedia1.media.map((activityMedia, key) => {
                                                                activity_list.push(activityMedia1.id)
                                                                return (
                                                                    <tbody key={key} className={activityMedia.id === selectedActivityMediaId ? "selectedRow" : ""}>
                                                                        {
                                                                            countOccurnce(activityMedia1.id) === 1 &&
                                                                            <tr className="activityName">
                                                                                <td colSpan={6} >
                                                                                    {
                                                                                        activityMedia1.image !== null &&
                                                                                        <span>
                                                                                            <img src={`data:image/png;base64, ${atob(activityMedia1.image)}`} width={"30"} height={"30"} style={{ paddingRight: '5px', marginBottom: "-10px" }} />
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                            <span>{activityMedia1.activity_text[getLanguageData(activityMedia1.activity_text)]?.name}</span>
                                                                                            :
                                                                                            undefined
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        <tr>
                                                                            <td className="">{activityMedia.code}</td>
                                                                            <td className="">{
                                                                                "media_text" in activityMedia ?
                                                                                    activityMedia.media_text.length > 0 ?
                                                                                        getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                            activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].title
                                                                                            :
                                                                                            undefined
                                                                                        :
                                                                                        undefined
                                                                                    :
                                                                                    undefined
                                                                            }</td>
                                                                            <td className="">{
                                                                                "media_text" in activityMedia ?
                                                                                    activityMedia.media_text.length > 0 ?
                                                                                        getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                            activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].link
                                                                                            :
                                                                                            "NULL"
                                                                                        :
                                                                                        "NULL"
                                                                                    :
                                                                                    "NULL"
                                                                            }</td>
                                                                            <td className="">{
                                                                                "media_text" in activityMedia ?
                                                                                    activityMedia.media_text.length > 0 ?
                                                                                        getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                            activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].mediaType
                                                                                            :
                                                                                            "NULL"
                                                                                        :
                                                                                        "NULL"
                                                                                    :
                                                                                    "NULL"
                                                                            }</td>
                                                                            {
                                                                                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                                <>
                                                                                    <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                        <img
                                                                                            src={require('../../../../images/edit.svg')}
                                                                                            alt="edit"
                                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                document.getElementById('title').value = "";
                                                                                                document.getElementById('description').value = "";
                                                                                                props.actions.selectedGenericActivity(activityMedia1.category);
                                                                                                window.scrollTo(0, 0);
                                                                                                setActivityCrop(activityMedia1.activity_crop);
                                                                                                editActivityMediaContent(activityMedia);
                                                                                                setSelectedActivityMedia(activityMedia);
                                                                                                setSelectedActivityMediaId(activityMedia.activity_id)
                                                                                                setClickedEditButton(clickedEditButton + 1)

                                                                                                props.actions.specificCropActivityListRequest1({
                                                                                                    "activity_id": activityMedia.activity_id,
                                                                                                    "crop_id": props.selected_crop_id,
                                                                                                    "organisation": props.organisation,
                                                                                                })
                                                                                            }}

                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                        <img
                                                                                            src={require('../../../../images/delete.png')}
                                                                                            alt="delete"
                                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                setSelectedActivityMedia(activityMedia);
                                                                                                openModal();
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                                        <>
                                                                                            <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                                <img
                                                                                                    src={require('../../../../images/edit.svg')}
                                                                                                    alt="edit"
                                                                                                    style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        document.getElementById('title').value = "";
                                                                                                        document.getElementById('description').value = "";
                                                                                                        props.actions.selectedGenericActivity(activityMedia1.category);
                                                                                                        window.scrollTo(0, 0);
                                                                                                        setActivityCrop(activityMedia1.activity_crop);
                                                                                                        editActivityMediaContent(activityMedia);
                                                                                                        setSelectedActivityMedia(activityMedia);
                                                                                                        setSelectedActivityMediaId(activityMedia.activity_id)
                                                                                                        setClickedEditButton(clickedEditButton + 1)

                                                                                                        props.actions.specificCropActivityListRequest1({
                                                                                                            "activity_id": activityMedia.activity_id,
                                                                                                            "crop_id": props.selected_crop_id,
                                                                                                            "organisation": props.organisation,
                                                                                                        })
                                                                                                    }}

                                                                                                />
                                                                                            </td>
                                                                                            <td style={{ justifyContent: "center", alignContent: "center" }}>
                                                                                                <img
                                                                                                    src={require('../../../../images/delete.png')}
                                                                                                    alt="delete"
                                                                                                    style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        setSelectedActivityMedia(activityMedia);
                                                                                                        openModal();
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        </>
                                                                            }
                                                                        </tr>
                                                                        {
                                                                            props.user_role_type === "Viewer" &&
                                                                                "media_text" in activityMedia ?
                                                                                activityMedia.media_text.length > 0 ?
                                                                                    <tr>
                                                                                        <td colSpan="10">
                                                                                            {
                                                                                                getLanguage(activityMedia.id, activityMedia1.id) !== -1 ?
                                                                                                    activityMedia.media_text[getLanguage(activityMedia.id, activityMedia1.id)].description
                                                                                                    :
                                                                                                    undefined
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                    :
                                                                                    undefined
                                                                                :
                                                                                undefined
                                                                        }
                                                                    </tbody>
                                                                )
                                                            })
                                                    )
                                                })
                                    }
                                </table>
                                {
                                    (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                        renderModal()
                                    :
                                        (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                            renderModal()
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        season_list: state.season.season_list,
        activity_media_list: state.activityMedia.specific_crop_activity_list,
        activity_register_loading: state.activityMedia.activity_register_loading,
        specific_crop_activity_list_loading: state.activityMedia.specific_crop_activity_list_loading,
        specific_crop_activity_list: state.activityMedia.specific_crop_activity_list,
        selected_crop_id: state.crop.selected_crop_id,
        all_media_list: state.activityMedia.all_media_list,
        all_media_list_loading: state.activityMedia.all_media_list_loading,
        selected_generic_activity: state.activityMedia.selected_generic_activity,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        organisation: state.auth.organization,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                removeSpecificActivity,
                deleteActivityMedia,
                clearSelectedActivityMedia,
                allMediaListRequest,
                specificCropActivityListRequest1,
                allGenericListRequest,
                selectedGenericActivity
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Activity);
