import React, { useState, useEffect } from 'react';
import './index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../components/login/action';
import { organizationRequest } from '../../components/organization/action';
import { organisationSelectionRequest } from "../../components/login/action";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../common/spinner';

const LandingOrganization = props => {
    const [showDropDown, setShowDropDown] = useState();

    const showMenu = (e) => {
        e.stopPropagation();
        setShowDropDown(true);
    }

    useEffect(() => {
        props.actions.organizationRequest();
    }, []);

    const selectOrganization = (selectedOrganization) => {
        props.actions.organisationSelectionRequest(selectedOrganization);
    }

    return (
        <div
            className="landingContainer"
            onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(false);
            }}
        >
            <ToastContainer />
            {
                props.loading === true && <Spinner />
            }
            <div className="leftContainer">
                <div className="cardContainerBody">
                    <img style={{ width: '85%', }} className="landingImage" src={require('../../images/agricoach-bg.png')} alt="homeImage" />
                    <img className="iconContainer1" src={require('../../images/agricoach_logo.png')} alt="logo" />
                </div>
            </div>

            <div className="rightContainer12">
                <div className="headerContainer">
                    <div className="userContainer">
                        <div className="usernameContainer">
                            <div className="userImageContainer">
                                {
                                    (props.umva_photo !== null && props.umva_photo !== undefined && props.umva_photo !== "") &&
                                    <img
                                        src={`data:image/png;base64,${props.umva_photo}`}
                                        alt="user image"
                                        width="50"
                                        height="50"
                                        className="userImage"
                                    />
                                }
                            </div>
                            <span className="date">
                                Welcome, {props.umva_id}
                            </span>
                        </div>
                    </div>

                    <div className="languageContainer">
                        <img
                            src={require('../../images/setting.png')}
                            alt="dropdown"
                            onClick={(e) => { showMenu(e) }}
                            className="pointer"
                        />
                        {
                            showDropDown === true &&
                            <div className="toogleLangauge">
                                <div className="toggleLanguage">
                                    <div
                                        className="languageToogleContainer"
                                        onClick={() => { props.actions.logout() }}
                                    >
                                        <span>&#8226; Logout</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="titleContainer">
                    <span className="date">Choose Organization</span>
                </div>

                <div className="organizationContainer" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {
                        props.organization_list.length > 0 &&
                        props.organization_list.map((org, id) => {
                            return (
                                <div
                                    key={id}
                                    className="cardContainer1"
                                    onClick={() => {
                                        selectOrganization(org)
                                    }}
                                >
                                    <div className="cardImageContainer">
                                        <div className="cropImage" style={{ backgroundColor: '#4cba6a' }}>
                                            <img src={require('../../images/organization.png')} />
                                        </div>
                                    </div>
                                    <div className="cardNameContainer1">
                                        <span>{org.organisation}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="bottomContainer"></div>
            </div>

            <div className="emptyContainer"></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.language,
        umva_id: state.auth.umva_id,
        user_role_type: state.auth.user_role_type,
        umva_photo: state.auth.umva_photo,
        organization: state.auth.organization,
        organization_list: state.organization.organization_list,
        loading: state.auth.loading
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                organizationRequest,
                logout,
                organisationSelectionRequest,
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingOrganization);
