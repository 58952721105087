import axios from 'axios';
import config from './config';
import history from "./history";
import jwt from "jsonwebtoken";  

const Api = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_NEPAL_AGRICOACH_BASE_URL, 
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };

    let api = axios.create(defaultOptions);

    api.interceptors.request.use(
        function (config) {
            const token = localStorage.getItem("token");
            const country = localStorage.getItem('country');

            if (token) {
                config.headers.Authorization = token ? `Bearer ${token}` : "";
                const tokenDecode = jwt.decode(token);
                //config.headers["X-localization"] = localStorage.getItem("i18nextLng");
                // config.headers["X-Country"] = country;

                const expTime = tokenDecode.exp;
                const currentTime = Math.round(new Date().getTime() / 1000);

                if (currentTime > expTime) {
                    delete config.headers.Authorization;
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    history.replace("/");
                }
            }
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );
    return api;
}

export default Api();

export const TEST_AGRICOACH_API = axios.create({
    baseURL: config.TEST_AGRICOACH.URL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + config.TEST_AGRICOACH.TOKEN
    }
})

export const AGRICOACH_API = axios.create({
    baseURL: config.AGRICOACH.URL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + config.AGRICOACH.TOKEN
    }
})

export const TEST_IMPLEMENTOR_API = axios.create({
    baseURL: config.TEST_IMPLEMENTOR.URL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + config.TEST_IMPLEMENTOR.TOKEN
    }
})

export const IMPLEMENTOR_API = axios.create({
    baseURL: process.env.REACT_APP_TEST_IMPLEMENTOR_URL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_TEST_IMPLEMENTOR_TOKEN
    }
})

export const NEPAL_TEST_API = axios.create({
    baseURL: config.NEPAL_TEST_AGRICOACH.URL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + config.NEPAL_TEST_AGRICOACH.TOKEN
    }
})
