import React, { useState, useEffect } from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import FileType from '../../../common/fileType';
import TextArea from '../../../common/textArea';
import Switch from '../../../common/switch';
import ActiivtyList from './activityList';
import Spinner from '../../../common/spinner';
import CustomButton from '../../../common/button';
import { 
    activityListRequest
} from '../action';  

import { 
    registerActivity,
    getCategory,
    removeActivities,
    updateActivityStatus
 } from '../action';

import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { languageUpdate } from '../../../trans/actions';
import { GetOrganisationId } from '../../lib/getOrganisationId';
import { GetToOrganisation } from '../../lib/getToOrganisation';

const Index = (props) => {
    const [selectMode, setSelectedMode] = useState('all');
    const [activityList, setActivityList] = useState(props.activity_list);
    var image_type = ["image/png", "image/jpeg"];
    const [file, setFile] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);

    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [errorSorting, setErrorSorting] = useState(false);

    const [data, setData] = useState([]);

    const [activityText, setActivityText] = useState([]);

    const [isGeneric, setIsGeneric] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState("");
    const [activityCategoryList, setActivityCategoryList] = useState(props.activity_category_list);
    const [selectCategory, setSelectCategory] = useState('main');
    const [cropSpecificCategoryList, setCropSpecificCategory] = useState([]);
    const [genericCategoryList, setGenericCategory] = useState([]);
    const [sorting, setSorting] = useState("");
    const [edited, setEditedCategory] = useState("");
    const [activity_names, setActivityNames] = useState([]);
    const [activityError, setActivityError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);

    useEffect(()=>{
        setFileSizeError(fileSizeError);
        setFileTypeError(fileTypeError);
        setActivityNames(activity_names);
        setActivityError(activityError);
        setCategoryError(categoryError);
        setSelectedMode(selectMode);
    },[fileSizeError, fileTypeError, activity_names, setActivityError, categoryError, selectMode]);

    useEffect(()=>{
        setActivityList(props.activity_list);
    },[props.activity_list]);
    
    useEffect(()=>{
        setActivityText(activityText);
    },[activityText]);

    const changeMode = (mode) => {
        setSelectedMode(mode);

        if(mode === "generic"){
            let data = Object.values(props.activity_list).filter((tempData)=>{
                return tempData.generic === true
            })
            setActivityList(data);
        }
        else if(mode === "specific"){
            let data = Object.values(props.activity_list).filter((tempData)=>{
                return tempData.generic === false
            })
            setActivityList(data);
        }
        else{
            setActivityList(props.activity_list);
        }
    }

    useEffect(()=>{
        setActivityList(activityList);
    },[activityList])

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        let data1 = props.generic_category.sort((a,b)=>(a.id > b.id ? 1 : -1));
        let data2 = props.crop_specific_category.sort((a,b)=>(a.id > b.id ? 1 : -1));
        setCropSpecificCategory(data2);
        setGenericCategory(data1);
    },[]);

    const getLangIndex = () => {
        let data0 = Object.values(activityText).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return data0;
    }  

    const getLanguageIndex = () => {
        let data = Object.values(activityText).findIndex((temp_data)=>{
            return temp_data.locale === "en_EN"
        });
        return data;
    }  

    useEffect(()=>{
        let data0 = {"sorting": 0}
        let data1 = {...data, ...data0};
        setData(data1);
    },[props.activity_category_list]);

    const textChange = (text, name) => {
        if(text === "name" || text === "description"){
            let data0 = getLangIndex() in activityText ? activityText[getLangIndex()]: [];

            let activity = "name" in data0 ? {"name": data0.name} : {"name": ""};
            let description = "description" in data0 ? {"description": data0.description} : {"description": ""};

            let data1 = {...data0, ...activity, ...description};

            data1[text] = name;
            let language = {"locale": languageContent};

            let data2 = {...data1, ...language};

            let data3 = {[getLangIndex() === -1 ? Object.values(Array(activityText)[0]).length : getLangIndex()]: data2};

            let data4 = {...activityText, ...data3};

            setActivityText(data4);
        }
        else{
            if(text === 'isGeneric'){
                setIsGeneric(!isGeneric);
                setSelectedCategory("");
                setActivityCategoryList([]);
                setEditedCategory("sub");

                let data0 = {"category_id" : 0};
                let data1 = {"sorting": 0};
                let data2 = {...data, ...data0, ...data1};
                setData(data2);
            }
            else if(text === 'category_id'){
                let data1 = {"sorting": 0};
                let data2 = {...data, ...data1};
                setData(data2);

                props.actions.activityListRequest({"category": parseInt(name)})

                setSelectedCategory(name);
            }
            else if(text === "sorting"){
                setErrorSorting(false);
                setSorting(name);
            }

            let data0 = [];
            data0[text] = name;

            let data1 = {...data, ...data0};
            setData(data1);            
        }
    }
    
    const getLocaleData = (localeData) => {
        let data = localeData.findIndex((tempData)=>{return tempData.locale.includes(props.lang)});
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const onSaveButtonClick = () => {
        let activity_text = [];

        Object.values(activityText).map((tempActivity)=>{
            activity_text.push({...tempActivity})
        });

        let data0 = {["activityText"]: JSON.stringify(activity_text)};
        let data2 = "isGeneric" in data  === false ? {"isGeneric": "false"} : {"isGeneric": data.isGeneric.toString()};
        let data3 = "isWeatherDependent" in data === false ? {"isWeatherDependent": "false"} : {"isWeatherDependent": data.isWeatherDependent.toString()};
        let data5 = "category_id" in data === false ? {"category_id" : ""} : selectCategory === "main" ? {"category_id": ""} : {"category_id": data.category_id};
        let data6 = "sorting" in data === false ? {"sorting" : "end"} : sorting !== "" ? {"sorting": sorting} : {"sorting": (data.sorting === "0" || data.sorting === 0) ? "end" : data.sorting};
        
        data6.sorting = (data6.sorting === "0" || data6.sorting === "end") ? "end" : data6.sorting * 1;
        let data7 = "id" in data === false && {"id" : ""}
        let data8 = {"category": edited !== "" ? edited :selectCategory};
        
        if(data5.category_id === ""){
            if(data7.id === ""){
                var data4 = {...data, ...data6, ...data2, ...data3, ...data8};
            }
            else{
                var data4 = {...data, ...data6, ...data7, ...data2, ...data3, ...data8};
            }
        }
        else{
            if(data7.id === ""){
                if(data.category === "main"){
                    var data4 = {...data, ...data6, ...data2, ...data3, ...data8};
                }
                else{
                    var data4 = {...data, ...data5, ...data6, ...data2, ...data3, ...data8};
                }
            }   
            else{
                if(data.category === "main"){
                    var data4 = {...data, ...data6, ...data7, ...data2, ...data3, ...data8};
                }
                else{
                    var data4 = {...data, ...data6, ...data7, ...data2, ...data3, ...data8};
                }
            }
        }
        
        let data9 = {"activity_image": Object.keys(data).length > 0 ? file === "" ? data.activity_image ? atob(data.activity_image) : file : file : file};
        let data1 = {...data0, ...data4, ...data9};

        if(fileTypeError === false){
            setFileTypeError(false);
            if(fileSizeError === false){
                setFileSizeError(false); 
                
                var temp_data1 = [];
                if(JSON.parse(data1.activityText).length === 0){
                    setActivityError(true);
                    props.actions.languageUpdate(props.language_list[0].locale.slice(0,2));
                    window.scrollTo(0,0);
                }
                else{
                    JSON.parse(data1.activityText).map((tempData)=>{
                        let temp_activity_name = tempData.locale;
                        temp_data1.push(temp_activity_name);
                    });
                    setActivityNames(temp_data1);
                }

                if(data1.isGeneric === true && data1.category_id === "" && data1.category === "sub"){
                    setCategoryError(true);
                }   
                else{
                    setCategoryError(false); 
                    props.actions.registerActivity(data1);
                }
            }
            else{
                setFileSizeError(true); 
            }
        }
        else{
            setFileTypeError(true);
        }
    }

    const getLanguageData = () => {
        switch(props.lang){
            case "en":
                props.actions.languageUpdate("fr");
                break;
            case "fr":
                props.actions.languageUpdate("rn");
                break;
            case "rn":
                props.actions.languageUpdate("en");
                break;
            default:
                props.actions.languageUpdate("en");
                break;
        }
    }

    const editVariety = (activity)=> {
        setTimeout(function() { getLanguageData(); }, 100);

        window.scrollTo(0, 0);

        setErrorSorting(false);
        setData([]);
        setActivityText([]);
        setIsGeneric(activity.generic);
        if(activity.id === activity.category){
            setSorting(activity.sorting.toString());
            setEditedCategory("main");

            var data0 = {
                "isGeneric": activity.generic,
                "isWeatherDependent": activity.weatherDependent,
                "sorting": activity.sorting * 1,
                "id": parseInt(activity.id),
                "activity_image": activity.image
            }
        }
        else{
            setSorting(activity.sorting.toString());
            setEditedCategory("sub");
            setSelectCategory("sub");

            var data0 = {
                "isGeneric": activity.generic,
                "isWeatherDependent": activity.weatherDependent,
                "sorting": activity.sorting * 1,
                "id": parseInt(activity.id),
                "category_id": parseInt(activity.category),
                "activity_image": activity.image
            }
        }

        let data1 = [];
       
        if("activity_text" in activity){
            Object.values(activity.activity_text).map((tempData)=>{
                let data2 = [];
                data2.name = tempData.name;
                data2.description = tempData.description;
                data2.locale = tempData.locale;
                data1.push(data2);
            });
        }

        props.actions.activityListRequest({"category": parseInt(activity.category)})
        document.getElementById('generic').value = activity.generic;
        document.getElementById('weather_dependent').value = activity.weatherDependent;
        document.getElementById('name').value = "activity_text" in activity === true ? langContent in activity.activity_text === true ? activity.activity_text[langContent].name : "" : "";
        document.getElementById('description').value = "activity_text" in activity === true ? langContent in activity.activity_text === true ? activity.activity_text[langContent].description : "" : "";
        
        setData(data0);
        setActivityText(data1);

        setTimeout(function() { getLanguageData1(); }, 100);
    }

    const getLanguageData1 = () => {
        switch(props.lang){
            case "fr":
                props.actions.languageUpdate("fr");
                break;
            case "rn":
                props.actions.languageUpdate("rn");
                break;
            case "en":
                props.actions.languageUpdate("en");
                break;
            default:
                props.actions.languageUpdate("en");
                break;
        }
    }

    const onDelete = () => {
        window.scrollTo(0, 0);
        setData([]);
        setActivityText([]);
    }

    var activity_offset = data.length > 0 ? (data.category_id !== data.id) ? false : true  : false;
    
    useState(()=>{
        setSelectedCategory(selectedCategory)
    },[selectedCategory])

    useState(()=>{
        setIsGeneric(isGeneric);
    },[isGeneric]);

    useEffect(()=>{
        setData(data);
    },[data]);

    useEffect(()=>{
        setErrorSorting(errorSorting);
    },[errorSorting]);

    useEffect(()=>{
        setActivityCategoryList(props.activity_category_list);
    },[props.activity_category_list]);

    const getSelectedLanguageData = (offset) => {
        let index = offset.findIndex((tmp)=>{return tmp.locale.includes(props.lang)});

        if(index === -1){
            let enIndex = offset.findIndex((tmp)=>{return tmp.locale === "en_EN"});
            return enIndex;
        }
        else{
            return index;
        }
    }

    const genericActivities = () => {
        return (
            genericCategoryList.length > 0 && 
                genericCategoryList.map((tempData,key)=>{
                    return (
                        <>
                            <option key={key} value={tempData.sorting}>{
                                tempData.activity_text.length > 0 ?
                                    tempData.activity_text[getSelectedLanguageData(tempData.activity_text)].name
                                :
                                    undefined
                            }</option>
                            {
                                key ===  props.generic_category.length - 1 &&
                                    <option value={"0"} selected="selected">{`End`}</option>
                            }
                        </>
                    )
                })
        )
    }

    const cropSpecificCategory = () => {
        return (
            cropSpecificCategoryList.length > 0 &&
                cropSpecificCategoryList.map((tempData,key)=>{
                    return (
                        <>
                            <option key={key} value={tempData.sorting}>{
                                tempData.activity_text.length > 0 ? 
                                    tempData.activity_text[getSelectedLanguageData(tempData.activity_text)].name
                                :
                                    undefined
                            }</option>
                            {
                                key ===  props.crop_specific_category.length - 1 &&
                                    <option value={"0"} selected="selected">{`End`}</option>
                            }
                        </>
                    )
                })
        )
    }

    useEffect(()=>{
        setEditedCategory(edited);
    },[edited]);

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const handleChange = (event) => {
        if(image_type.includes(event.target.files[0].type) === true){
            setFileTypeError(false);
            if(event.target.files[0].size <= 10000){
                setFileSizeError(false);
                toDataURL(URL.createObjectURL(event.target.files[0]),
                    function (dataUrl) {
                        let temp_data_url = dataUrl.split(",");
                        setFile(temp_data_url[1]);
                    }
                )
            }
            else{
                setFileSizeError(true); 
                setFile("");
            }
        }
        else{
            setFileTypeError(true);
            setFile("");
        }
    }

    const toDataURL = (src, callback, outputFormat) => {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          let dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
        };
        image.src = src;
        if (image.complete || image.complete === undefined) {
          image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
          image.src = src;
        }
    }

    useEffect(()=>{
        setFile(file)
    },[file]);

    useEffect(()=>{
        setSelectCategory(selectCategory);
    },[selectCategory]);

    useEffect(()=>{
        setSorting(sorting);
    },[sorting])

    useEffect(()=>{
        props.actions.getCategory();
    },[props.activity_list]);

    const getLanguageAvtivity = (activityData) => {
        let data = activityData.findIndex((tempData)=>{
            return tempData.locale === languageContent;
        });
        return data;
    }

    const toggleStatus = (toggle_data) => {
        let org_id = GetOrganisationId(props.organization_list, props.auth);
        let {id, category, status} = toggle_data;

        const params = {
            id: id,
            category_id: category,
            status: !status,
            org_id: org_id,
        };

        props.actions.updateActivityStatus(params);
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderData = () => {
        return (
            <>
                {
                    languageLength.map((lang, key)=>{
                        return (
                            langContent === lang &&
                                <div key={key}>
                                    {
                                        <TextBox 
                                            label="Category Name" 
                                            name="name" 
                                            id="name"
                                            onTextChange={textChange} 
                                            value={
                                                getLangIndex() in activityText ? activityText[getLangIndex()].name : undefined
                                            }
                                            value1={
                                                props.lang !== "en" &&
                                                    Object.values(activityText).length > 0 ?
                                                        langContent !== 0 ?
                                                            activityText[getLanguageIndex()].name
                                                        :
                                                            activityText[getLanguageIndex()].name
                                                    :   
                                                        undefined
                                            }
                                        />
                                    }
                                </div>
                        )
                    })
                }

                {
                    languageLength.map((lang, key)=>{
                        return (
                            langContent === lang &&
                                <div key={key}>
                                    {
                                        <TextArea 
                                            label="Description" 
                                            name="description" 
                                            id="description"
                                            onTextChange={textChange} 
                                            value={
                                                getLangIndex() in activityText ? activityText[getLangIndex()].description : undefined
                                            }
                                            value1={
                                                props.lang !== "en" &&
                                                    Object.values(activityText).length > 0 ?
                                                        langContent !== 0 ?
                                                            activityText[getLanguageIndex()].description
                                                        :
                                                            activityText[getLanguageIndex()].description
                                                    :
                                                        undefined
                                            }
                                        />
                                    }
                                </div>
                        )
                    })
                }

                <FileType 
                    label={"Activity Image"}
                    handleChange={handleChange}
                    file={
                        Object.keys(data).length > 0 ?
                            file === "" ?
                                data.activity_image ? 
                                    atob(data.activity_image)
                                :
                                    file
                            :
                                file
                        :
                            file
                    }
                    activity={true}
                    error={fileSizeError === true ? "Maximum limit size exceeds 10 KB" : undefined}
                    error1={fileTypeError === true ? ".jpeg and .png file type are only allowed." : undefined}
                />

                <Switch
                    id="generic"
                    label="Generic" 
                    name="isGeneric"
                    onTextChange={textChange}
                    selected={
                        "isGeneric" in data ?
                            data.isGeneric
                        :
                            false
                    }
                    checked={
                        "isGeneric" in data ?
                            data.isGeneric
                        :
                            false
                    }
                />
            
                {
                    data.isGeneric === true &&
                        <>
                            <div className="TextBoxContainer">
                                <div className="leftSelectLabelContainer1">
                                    <span>Category</span>
                                </div>

                                <div className="rightSelectLabelContainer1">
                                    <div style={{display: "flex",flexDirection: 'row'}}>
                                        <input type="radio" id="main" name="category" 
                                            value="main" 
                                            className={
                                                edited === "main"
                                                ? "selected"
                                                :   edited === "" 
                                                ? selectCategory === "main" ? "selected" : "" 
                                                : ""
                                            }
                                            checked={
                                                edited === "main"
                                                ? "checked"
                                                :   edited === "" 
                                                ? selectCategory === "main" ? "checked" : "" 
                                                : ""
                                            } 
                                            onChange={(e)=>{
                                                setSelectCategory(e.target.value)
                                                setEditedCategory("main");
                                                setCategoryError(false);
                                                setData({...data, ...{"sorting": 0}})
                                                setSorting("end")
                                            }} 
                                        />
                                        <label>Main</label>
                                        <input type="radio" id="sub" name="category" value="sub" 
                                            className={
                                                edited === "sub"
                                                ? "selected"
                                                :   edited === "" 
                                                ? selectCategory === "sub" ? "selected" : "" 
                                                : ""
                                            }
                                            checked={
                                                edited === "sub"
                                                ? "checked"
                                                :   edited === "" 
                                                ? selectCategory === "sub" ? "checked" : "" 
                                                : ""
                                            } 
                                            onChange={(e)=>{
                                                setSelectCategory(e.target.value)
                                                setEditedCategory("sub")
                                                setSorting("end")
                                            }} 
                                        />
                                        <label>Sub</label>
                                    </div>
                                </div>
                            </div>

                            {
                                edited === "sub" && activity_offset === false &&
                                    <div className="TextBoxContainer">
                                        <div className="leftSelectLabelContainer1">
                                            <span>Activity</span>
                                        </div>

                                        <div className="rightSelectLabelContainer1">
                                            <div style={{display: "flex",flexDirection: 'column'}}>
                                                {
                                                    categoryError === true &&
                                                        <span className="errorText">{"Activity field is required."}</span>
                                                }
                                                <select 
                                                    className="selectInput"
                                                    name="category_id"
                                                    value={"id" in data === true ? data.category_id : selectedCategory}
                                                    onChange={(e)=>{
                                                        setCategoryError(false);
                                                        textChange("category_id",parseInt(e.target.value));
                                                    }}
                                                >
                                                    {
                                                        props.generic_category.length > 0 && 
                                                            props.generic_category.map((tempData,key)=>{
                                                                return (
                                                                    <option key={key} value={tempData.id}>{
                                                                        tempData.activity_text.length > 0 ?
                                                                            tempData.activity_text[getLanguageAvtivity(tempData.activity_text)] !== undefined &&
                                                                                tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].locale === languageContent ? 
                                                                                    tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].name
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }</option>
                                                                )
                                                            })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }   
                        </>
                }

                <Switch
                    id="weather_dependent"
                    label="Weather dependent" 
                    name="isWeatherDependent"
                    onTextChange={textChange}
                    selected={
                        "isWeatherDependent" in data === true ?
                            data.isWeatherDependent
                        :
                            false
                    }
                    checked={
                        "isWeatherDependent" in data === true ?
                            data.isWeatherDependent
                        :
                            false
                    }
                />

                <div className="TextBoxContainer">
                    <div className="leftSelectLabelContainer1">
                        <span>Add before</span>
                    </div>

                    <div className="rightSelectLabelContainer1">
                        <div style={{display: "flex",flexDirection: 'column'}}>
                            {
                                data.isGeneric === true ? 
                                    edited === "main" ?
                                        <select 
                                            className="selectInput"
                                            name="sorting"
                                            value={sorting !== "" ? sorting : data.sorting}
                                            onChange={(e)=>{
                                                textChange("sorting",e.target.value);
                                                setSorting(e.target.value)
                                            }}
                                        >
                                            {
                                                props.generic_category.length > 0 && 
                                                    props.generic_category.map((tempData,key)=>{
                                                        return (
                                                            <>
                                                                <option key={key} value={tempData.sorting}>{
                                                                    tempData.activity_text.length > 0 ?
                                                                        tempData.activity_text[getLanguageAvtivity(tempData.activity_text)] !== undefined &&
                                                                            tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].locale === languageContent ? 
                                                                                tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].name
                                                                            :
                                                                                undefined
                                                                    :
                                                                        undefined
                                                                }</option>
                                                            </>
                                                        )
                                                    })
                                            }
                                            <option value={"0"} selected="selected">{`End`}</option>
                                        </select>
                                    :
                                        <select 
                                            className="selectInput"
                                            name="sorting"
                                            value={sorting !== "" ? sorting : data.sorting}
                                            onChange={(e)=>{
                                                textChange("sorting",e.target.value);
                                                setSorting(e.target.value)
                                            }}
                                        >
                                            {
                                                activityCategoryList.length > 0 ?
                                                    selectCategory === "main" ? 
                                                        isGeneric === false ? 
                                                            cropSpecificCategory()
                                                    :
                                                        activityCategoryList.map((tempData1,key)=>{
                                                            return (
                                                                <>
                                                                    <option key={key} value={tempData1.sorting}>{
                                                                        langContent in tempData1.activity_text && tempData1.activity_text[langContent].name
                                                                    }</option>
                                                                    {
                                                                        key === activityCategoryList.length - 1 &&
                                                                            <option value={"0"} selected="selected">{`End`}</option>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    :
                                                    activityCategoryList.map((tempData1,key)=>{
                                                        return (
                                                            <>
                                                                <option key={key} value={tempData1.sorting}>{
                                                                    langContent in tempData1.activity_text && tempData1.activity_text[langContent].name
                                                                }</option>
                                                                {
                                                                    key === activityCategoryList.length - 1 &&
                                                                        <option value={"0"} selected="selected">{`End`}</option>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                :
                                                    activityCategoryList.length === 0 ?
                                                        selectCategory === "main" ? 
                                                            <option value={"0"} selected="selected">{`End`}</option>
                                                        :
                                                            <option value={"0"} selected="selected">{`End`}</option>
                                                    :
                                                        isGeneric === false ? 
                                                            cropSpecificCategory()
                                                    :
                                                            genericActivities()
                                            }
                                        </select>
                                :
                                    <select 
                                        className="selectInput"
                                        name="sorting"
                                        value={sorting !== "" ? sorting : data.sorting}
                                        onChange={(e)=>{
                                            textChange("sorting",e.target.value);
                                        }}
                                    >
                                        {
                                            isGeneric === false ? 
                                                props.crop_specific_category.length > 0 &&
                                                        props.crop_specific_category.map((tempData,key)=>{
                                                        return (
                                                            <option key={key} value={tempData.sorting}>{
                                                                tempData.activity_text.length > 0 ? 
                                                                    tempData.activity_text[getLocaleData(tempData.activity_text)] !== undefined &&
                                                                        tempData.activity_text[getLocaleData(tempData.activity_text)].name
                                                                :
                                                                    undefined
                                                            }</option>
                                                        )
                                                    })
                                            :
                                                props.generic_category.length > 0 && 
                                                    props.generic_category.map((tempData,key)=>{
                                                        return (
                                                            <option key={key} value={tempData.sorting}>{
                                                                tempData.activity_text.length > 0 ?
                                                                    tempData.activity_text[getLocaleData(tempData.activity_text)] !== undefined &&
                                                                        tempData.activity_text[getLocaleData(tempData.activity_text)].name
                                                                :
                                                                    undefined
                                                            }</option>
                                                        )
                                                    })
                                        }
                                        <option value={"0"} selected="selected">{`End`}</option>
                                    </select>
                            }
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                    <div className="leftSelectLabelContainer1"></div>
                    
                    <div className="rightSelectLabelContainer1">
                        <CustomButton
                            name={`Save Activity names and Categories`} 
                            onSaveButtonClick={onSaveButtonClick}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="mediaContainer">
            <Header header={false} />

            <Breadcrumb />

            <ToastContainer/>

            <div className="generalBody">
                <div className="formCard" style={{flexDirection: 'column'}}>
                    {
                        props.loading === true ?
                            <Spinner />
                        :
                            (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                               renderData()
                            :
                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) ?
                                    renderData()
                                :
                                    undefined
                    }

                    <div className="tabHeader" style={{marginBottom: '0px'}}>
                        <div 
                            onClick={()=>{changeMode('all')}}
                            className={ selectMode === "all" ? "activeFirstTab" : "tabContent"}
                        >
                            {"All"}
                        </div>

                        <div
                            onClick={()=>{changeMode('generic')}}
                            className={ selectMode === "generic" ? "activeMiddleTab" : "tabContent"}
                        >
                            {'Generic'}
                        </div>

                        <div
                            onClick={()=>{changeMode('specific')}}
                            className={ selectMode === "specific" ? "activeLastTab" : "tabContent"}
                        >
                            {'Specific'}
                        </div>
                    </div>

                    <ActiivtyList 
                        editVariety={editVariety} onDelete={onDelete}
                        activity_list={activityList}
                        toggleStatus={toggleStatus}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        generic_category: state.activity.generic_category,
        crop_specific_category: state.activity.crop_specific_category,
        user_role_type: state.auth.user_role_type,
        loading: state.activity.loading,
        activity_category_list: state.activity.activity_category_list,
        activity_category_list_loading: state.activity.activity_category_list_loading,
        language_list: state.lang.language_list,
        activity_list: state.activity.activity_list,
        organization_list: state.organization.organization_list,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                registerActivity,
                getCategory,
                activityListRequest,
                removeActivities,
                languageUpdate,
                updateActivityStatus,
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);
