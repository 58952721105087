import React, { useEffect, useState } from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import Spinner from '../../../common/spinner';
import CustomButton from '../../../common/button';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import { 
    scaleTypeRequest,
    scaleValueRequest,
    addScaleValue,
    deleteScaleValues,
    registerScaleTypeRequest,
    deleteScaleType
} from '../action';

import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { GetToOrganisation } from '../../lib/getToOrganisation';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    const [deleteModalIsOpen,setDeleteModalIsOpen] = React.useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [selectedScaleValue, setSelectedScaleValue] = useState({});
    const [selectedScaleValueId ,setSelectedScaleValueId] = useState("");
    const [data, setData] = useState([]);
    const [scaleType, setScaleType] = useState([]);

    useEffect(()=>{
        setData(data)
    },[data]);

    useEffect(()=>{
        setScaleType(scaleType)
    },[scaleType]);

    useEffect(()=>{
        setSelectedScaleValue(selectedScaleValue);
        setSelectedScaleValueId(selectedScaleValueId);
    },[selectedScaleValue, selectedScaleValueId]);

    useEffect(()=>{
        props.actions.scaleTypeRequest({"org": "Auxfin Burundi"});
        props.actions.scaleValueRequest({"organisation": props.organization});
    },[]);

    const editScaleValue = (scale_value) => {
        let data = {
            "type": scale_value.type,
            "min": scale_value.min,
            "max": scale_value.max,
            "value": scale_value.value,
            "org": props.org,
            "unitOfMeasure": scale_value.unitOfMeasure,
            "scalesvalue_id": scale_value.scalesvalue_id
        }
        setData(data);
    }

    const textChange = (name, text) => {
       var data1 = {};
        if(name === "type"){
            let scale_type = props.scaleType.filter((tempData)=>{
                return tempData.id == text;
            })
            
            if(scale_type.length > 0){
                data1 = {[name]: scale_type[0].type};
            }
        }
        else{
            data1 = {[name]: text};
        }
       let org = {"org": props.org};
       let scale_id = {"scalesvalue_id": selectedScaleValue.scalesvalue_id};
       let data2 = {...data, ...data1, ...org, ...scale_id};
       setData(data2);
    }

    const onSaveButtonClick = () => {
        closeModal();
        props.actions.addScaleValue(data);
    }

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
        setDeleteModalIsOpen(false);
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const deleteData = () => {
        return (
            <Modal
                isOpen={ deleteModalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    setDeleteModalIsOpen(false);
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected Scale Value?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                props.actions.deleteScaleValues({"scalesvalue_id": selectedScaleValueId})
                                closeModal();
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal();
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const addData = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                {
                    <div>
                        <div>
                            <div className="">
                                <div className="TextBoxContainer">
                                    <div className="leftSelectLabelContainer4">
                                        <span>Scale Type</span>
                                    </div>

                                    <div className="rightSelectLabelContainer">
                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                            <select 
                                                id="scale_type"
                                                className="selectInput"
                                                name="type"
                                                defaultValue={
                                                    "settings_uid" in selectedScaleValue ? 
                                                        selectedScaleValue.id
                                                    :
                                                        undefined
                                                }
                                                onChange={(e)=>{
                                                    textChange("type",e.target.value)
                                                }}
                                            >
                                                <option>Choose</option>
                                                {
                                                    props.scaleType.length > 0 ?
                                                        props.scaleType.map((tempData, key)=>{
                                                            return (
                                                                <option key={key} value={tempData.id}>{tempData.type}</option>
                                                            )
                                                        })
                                                    :
                                                        undefined
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="TextBoxContainer">
                                    <div className="leftSelectLabelContainer4">
                                        <span>Unit of Measures</span>
                                    </div>

                                    <div className="rightSelectLabelContainer">
                                        <TextBox 
                                            label="" 
                                            name="unitOfMeasure"
                                            id="unitOfMeasure"
                                            onTextChange={textChange}
                                            value={"settings_uid" in selectedScaleValue ? selectedScaleValue.unitOfMeasure : undefined}
                                        />
                                    </div>
                                </div>
                            </div>              

                            <div className="rightFormCard" style={{marginLeft: "25%"}}>
                                <TextBox 
                                    id1="min"
                                    id2="max"
                                    id3="value"
                                    label="" 
                                    type="min_max_value" 
                                    name="min" 
                                    name1="max" 
                                    name2="value" 
                                    onTextChange={textChange} 
                                    value={"settings_uid" in selectedScaleValue ? selectedScaleValue.min : undefined}
                                    value1={"settings_uid" in selectedScaleValue ? selectedScaleValue.max : undefined}
                                    value2={"settings_uid" in selectedScaleValue ? selectedScaleValue.value : undefined}
                                />
                            </div>
                        </div>

                        <div 
                            className="customButton2"
                            onClick={()=>{onSaveButtonClick()}}
                        >
                            <img 
                                src={require('../../../images/save_white.png')} 
                                alt="save" 
                                className="buttonImageContainer" 
                            />

                            <div className="buttonTextContainer">
                                {`Save Scale Value`}
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        );
    }

    return (
        <div className="mediaContainer">
            <Header header={false} displayLanguage={true} />

            <Breadcrumb />

            <div className="generalBody">
                <ToastContainer/>
                {
                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                        deleteData()
                    :
                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                            deleteData()
                }

                {
                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                        addData()
                    :
                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                            addData()
                }
                {
                    props.delete_scale_type_loading === true || props.register_scale_type_loading === true || props.scale_type_list_loading === true || props.scale_value_list_loading === true || props.scale_value_add_loading === true ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column'}}>
                            {
                                (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                    <div 
                                        onClick={(e)=>{
                                            e.stopPropagation();
                                            e.preventDefault();
                                            openModal();
                                        }}
                                        style={{cursor: 'pointer'}}
                                    >
                                        Add Scale Value
                                    </div>
                                :
                                    (props.user_role_type === "Superuser" && props.umva_id === props.superuser) && 
                                        <div 
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                e.preventDefault();
                                                openModal();
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            Add Scale Value
                                        </div>
                            }
                            <table style={{width: "60%"}} className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Unit of Measure</th>
                                        <th>Min</th>
                                        <th>Max</th>
                                        <th>Value</th>
                                        {
                                            (props.user_role_type === "Superuser" && props.organisation === to_org) ? 
                                                <>
                                                    <th>
                                                        <span>Edit</span> 
                                                    </th>
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                                </>
                                            :
                                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) && 
                                                    <>
                                                        <th>
                                                            <span>Edit</span> 
                                                        </th>
                                                        <th>
                                                            <span>Delete</span> 
                                                        </th>
                                                    </>
                                        }
                                    </tr>
                                </thead> 
                                {
                                    props.scaleValue.length > 0 ?
                                        props.scaleValue.map((tempData,key)=>{
                                            return (
                                                <tbody key={key} className={selectedScaleValueId !== "" ? tempData.type+tempData.scalesvalue_id === selectedScaleValueId ? "selectedRow" : "" : ""}>
                                                    <tr>
                                                        <td>{tempData.type}</td>
                                                        <td>{tempData.display === true ? tempData.unitOfMeasure : ""}</td>
                                                        <td>{tempData.min}</td>
                                                        <td>{tempData.max}</td>
                                                        <td>{tempData.value}</td>
                                                        {
                                                            (props.user_role_type === "Superuser" && props.organisation === to_org) ? 
                                                                <>
                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                        <img 
                                                                            src={require('../../../images/edit.svg')} 
                                                                            alt="edit" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={(e)=>{
                                                                                editScaleValue(tempData)
                                                                                setSelectedScaleValue(tempData)
                                                                                setSelectedScaleValueId(tempData.type+tempData.scalesvalue_id)
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                openModal();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                        <img 
                                                                            src={require('../../../images/delete.png')} 
                                                                            alt="delete" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={(e)=>{
                                                                                setDeleteModalIsOpen(true);
                                                                                setSelectedScaleValueId(tempData.scalesvalue_id);
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            :
                                                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) && 
                                                                    <>
                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/edit.svg')} 
                                                                                alt="edit" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={(e)=>{
                                                                                    editScaleValue(tempData)
                                                                                    setSelectedScaleValue(tempData)
                                                                                    setSelectedScaleValueId(tempData.type+tempData.scalesvalue_id)
                                                                                    e.stopPropagation();
                                                                                    e.preventDefault();
                                                                                    openModal();
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td style={{justifyContent: "center",alignContent: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/delete.png')} 
                                                                                alt="delete" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={(e)=>{
                                                                                    setDeleteModalIsOpen(true);
                                                                                    setSelectedScaleValueId(tempData.scalesvalue_id);
                                                                                    e.stopPropagation();
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </>    
                                                        }
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    :
                                        undefined
                                }
                            </table>
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        scaleType: state.scaleValue.scale_type_list,
        scaleValue: state.scaleValue.scale_value_list,
        org: state.auth.organization,
        scale_type_list_loading: state.scaleValue.scale_type_list_loading,
        scale_value_list_loading: state.scaleValue.scale_value_list_loading,
        scale_value_add_loading: state.scaleValue.scale_value_add_loading,
        register_scale_type_loading: state.scaleValue.register_scale_type_loading,
        delete_scale_type_loading: state.scaleValue.delete_scale_type_loading,
        user_role_type: state.auth.user_role_type,
        organization: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                scaleTypeRequest,
                scaleValueRequest,
                addScaleValue,
                deleteScaleValues,
                registerScaleTypeRequest,
                deleteScaleType
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);
