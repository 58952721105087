import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux'; 
import { bindActionCreators } from 'redux';
import { login } from './login/action';

import I18n from '../trans/i18n';
import './index.css';
import { countryRequest } from '../components/organization/action';
import Spinner from '../common/spinner';
import { languageUpdate } from '../trans/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = props => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [umva_id, setUmvaId] = useState();
    const [password, setPassword] = useState();
    const [errorCountry, setErrorCountry] = useState(false);
    const [errorUmvaId, setErrorUmvaId] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    useEffect(() => {
        props.actions.countryRequest();
        localStorage.setItem('i18nextLng', "en");
        I18n.changeLanguage(localStorage.getItem('i18nextLng'));
        props.actions.languageUpdate("en");
    }, [props.actions]);

    useEffect(()=>{
        setCountries(props.country);
    },[props.country]);

    const onLoginButtonPressed = () => {
        if (umva_id && password && selectedCountry) {
            setErrorCountry(false);
            setErrorUmvaId(false);
            setErrorPassword(false);

            props.actions.login({
                country: selectedCountry,
                username: umva_id,
                password: password,
                role: 0
            })
        }
        else {
            if (!selectedCountry) {
                setErrorCountry(true)
            }
            if (!umva_id) {
                setErrorUmvaId(true)
            }
            if (!password) {
                setErrorPassword(true)
            }
        }
    }


    return (
        <div className="loginContainer">
            <ToastContainer />
            {
                props.loading === true && <Spinner />
            }
            <div className="bodyContainer">
                <div className="leftContainer1">
                    <div className="cardContainerBody">
                        <img style={{ width: '85%', }} className="landingImage" src={require('../images/agricoach-bg.png')} alt="homeImage" />
                        <img className="iconContainer1" src={require('../images/agricoach_logo.png')} alt="logo" />
                    </div>
                </div>

                <div className="rightContainer1">
                    <div className="formContainer">
                        <div className="inputContainer">
                            <div className="error-container p-offset-1">
                                <span className="error-container-text">
                                    {props.error !== undefined && props.error.length > 0 && "message" in props.error[0] ? props.error[0].message.company : null}
                                </span>

                                {
                                    errorCountry &&
                                        <span className="error-container-text">
                                            You must select Country
                                        </span>
                                }
                            </div>

                            <select
                                className="loginSelectContainer"
                                onChange={(e) => {
                                    setErrorCountry(false);
                                    setSelectedCountry(e.target.value);
                                }}
                            >
                                <option>Country</option>
                                {
                                    countries.length > 0 &&
                                        countries.map((tmp_country, key) => {
                                            return (
                                                <option
                                                    value={tmp_country.country_code}
                                                    key={key}
                                                >
                                                    {tmp_country.country}
                                                </option>
                                            )
                                        })
                                }
                            </select>

                            <div className="error-container p-offset-1">
                                <span className="error-container-text">
                                    {props.error != undefined && props.error.length > 0 && "message" in props.error ? props.error.message.username : null}
                                </span>

                                {
                                    errorUmvaId === true &&
                                    <span className="error-container-text">
                                        {I18n.t('You must enter UMVA ID')}
                                    </span>
                                }
                            </div>

                            <input
                                type="text"
                                placeholder={I18n.t('UMVA ID')}
                                autoComplete={"off"}
                                className="input-login"
                                name={"umva_id"}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && onLoginButtonPressed()
                                }}
                                onChange={(e) => {
                                    setUmvaId(e.target.value);
                                    setErrorUmvaId(false);
                                    setErrorPassword(false);
                                    setErrorCountry(false);
                                }}
                            />

                            <div className="error-container p-offset-1">
                                <span className="error-container-text">
                                    {props.error != undefined && props.error.length > 0 && "message" in props.error ? props.error.message.password : null}
                                </span>

                                {
                                    errorPassword === true &&
                                    <span className="error-container-text">
                                        {I18n.t('You must enter a password')}
                                    </span>
                                }
                            </div>

                            <input
                                type="password"
                                placeholder={I18n.t('Password')}
                                name="password"
                                className="input-login"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrorUmvaId(false);
                                    setErrorPassword(false);
                                    setErrorCountry(false);
                                }}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && onLoginButtonPressed()
                                }}
                            />
                        </div>

                        <div className="buttonContainer">
                            <button
                                className="button"
                                type="button"
                                onClick={() => { onLoginButtonPressed() }}
                                disabled={props.loading === true ? true : false}
                            >
                                <p>{I18n.t('Login')}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        loading: state.auth.loading,
        country: state.organization.country,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                login,
                languageUpdate,
                countryRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Login);